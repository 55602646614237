import styled from 'styled-components'

import { Typography } from '@material-ui/core'

import { HeaderVariant } from './HeaderVariant'
import { parseHeaderVariantToComponent } from './parseHeaderVariantToComponent'

interface Props {
  $headerVariant: HeaderVariant
}

export const StyledTypography = styled(Typography).attrs<Props>(({ $headerVariant }) => ({
  component: parseHeaderVariantToComponent($headerVariant),
  $headerVariant
}))<Props>`
  font-style: normal;
  font-weight: bold;
  margin: 16px 0;
  max-width: 632px;
  h6 + & {
    margin-top: 0;
  }
  ${({ theme, $headerVariant }) => `
    ${$headerVariant === 'h1' ? `      
        font-size: 56px;
        line-height: 72px;
      ` : $headerVariant === 'h2' ? `      
        font-size: 40px;
        line-height: 48px;
      ` : $headerVariant === 'h3' ? `      
        font-size: 28px;
        line-height: 40px;
      ` : $headerVariant === 'h4' ? `      
        font-size: 22px;
        line-height: 32px;
      ` : $headerVariant === 'h5' ? `      
        font-size: 18px;
        line-height: 24px;
      ` : $headerVariant === 'eyebrow' ? `
        font-size: 18px;
        line-height: 24px;  
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 0;
        color: ${theme.colors.blueLight};
      ` : $headerVariant === 'footer' ? `
        font-size: 11px;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      ` : $headerVariant === 'filter' ? `
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.gray800};
      ` : ''
    }
  `}
`
