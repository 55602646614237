const getConfig = require('next/config')
const publicRuntimeConfig = require('./publicRuntimeConfig')

// @todo konfiguracja pod obsługę TS
/*
import { NextConfig } from 'common/config/types'
const nextConfig: NextConfig = getConfig()
let nextServerRuntimeConfig = <NextConfig['serverRuntimeConfig']>{}
*/

const nextConfig = getConfig.default()
let nextServerRuntimeConfig

if (nextConfig) {
  const { serverRuntimeConfig: _serverRuntimeConfig } = nextConfig
  nextServerRuntimeConfig = _serverRuntimeConfig
}

const getServerRuntimeConfig = () => ({
  ...publicRuntimeConfig,
  PORT: nextServerRuntimeConfig?.PORT ?? process.env.PORT,
  REMOTE_API_HOST: nextServerRuntimeConfig?.REMOTE_API_HOST ?? process.env.REMOTE_API_HOST,
  REMOTE_ENOVATIS_HOST: nextServerRuntimeConfig?.REMOTE_ENOVATIS_HOST ?? process.env.REMOTE_ENOVATIS_HOST,
  REMOTE_API_URL: nextServerRuntimeConfig?.REMOTE_API_URL ?? process.env.REMOTE_API_URL,
  REMOTE_SSO_URL: nextServerRuntimeConfig?.REMOTE_SSO_URL ?? process.env.REMOTE_SSO_URL,
  REMOTE_SSO_PATTERN_STR: nextServerRuntimeConfig?.REMOTE_SSO_PATTERN_STR ?? process.env.REMOTE_SSO_PATTERN_STR,
  REMOTE_ENOVATIS_URL: nextServerRuntimeConfig?.REMOTE_ENOVATIS_URL ?? process.env.REMOTE_ENOVATIS_URL,
  CUSTOMERBOX_KEY: nextServerRuntimeConfig?.CUSTOMERBOX_KEY ?? process.env.CUSTOMERBOX_KEY,
  ELASTIC_ENABLED: nextServerRuntimeConfig?.ELASTIC_ENABLED ?? Boolean(Number(process.env.ELASTIC_ENABLED)),
  ELASTIC_NODE: nextServerRuntimeConfig?.ELASTIC_NODE ?? process.env.ELASTIC_NODE,
  ELASTIC_USERNAME: nextServerRuntimeConfig?.ELASTIC_USERNAME ?? process.env.ELASTIC_USERNAME,
  ELASTIC_PASSWORD: nextServerRuntimeConfig?.ELASTIC_PASSWORD ?? process.env.ELASTIC_PASSWORD,
  NODE_TLS_REJECT_UNAUTHORIZED: nextServerRuntimeConfig?.NODE_TLS_REJECT_UNAUTHORIZED ??
      Boolean(Number(process.env.NODE_TLS_REJECT_UNAUTHORIZED)),
  ENABLE_FILE_LOGGER: nextServerRuntimeConfig?.ENABLE_FILE_LOGGER ?? Boolean(Number(process.env.ENABLE_FILE_LOGGER)),
  GOOGLE_MAPS_SECRET_KEY: nextServerRuntimeConfig?.GOOGLE_MAPS_SECRET_KEY ?? process.env.GOOGLE_MAPS_SECRET_KEY,
  NEXT_TELEMETRY_DISABLED: nextServerRuntimeConfig?.NEXT_TELEMETRY_DISABLED ??
      Boolean(Number(process.env.NEXT_TELEMETRY_DISABLED)),
  JAEGER_ENABLED: nextServerRuntimeConfig?.JAEGER_ENABLED ?? Boolean(Number(process.env.JAEGER_ENABLED)),
  JAEGER_SERVICE_NAME: nextServerRuntimeConfig?.JAEGER_SERVICE_NAME ?? process.env.JAEGER_SERVICE_NAME,
  JAEGER_ENDPOINT: nextServerRuntimeConfig?.JAEGER_ENDPOINT ?? process.env.JAEGER_ENDPOINT,
  HEALTH_CHECK_KEY: nextServerRuntimeConfig?.HEALTH_CHECK_KEY ?? process.env.HEALTH_CHECK_KEY
})

module.exports = getServerRuntimeConfig()
