import React, { FunctionComponent, ReactNode } from 'react'

import Provider from './initialState'
import useViewStateManager from './useViewStateManager'

interface Props {
  children: ReactNode
}

const ViewProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const value = useViewStateManager()

  return (
    <Provider value={value}>
      {children}
    </Provider>
  )
}

export default ViewProvider
