import materialTheme from './materialTheme'
import colors from './colors'
import customShadows from './customShadows'
import gradients from './gradients'
import mobileMixins from './mobileMixins'
import reBrandingColors from './rebrandingColors'
import elevations from './elevations'

export default {
  ...materialTheme,
  gradients,
  colors,
  customShadows,
  mobileMixins,
  reBrandingColors,
  elevations
}
