import React from 'react'

import { InputLabelProps } from '@material-ui/core'
import { StyledInputLabel } from './styled'

interface FormLabel extends InputLabelProps {}

const FormLabel = (props: FormLabel) => <StyledInputLabel {...props} />

export default FormLabel
