/* eslint-disable */
import React from 'react'

import { Typography, TypographyProps } from '@material-ui/core'

export interface TextInterface extends TypographyProps {}

const Text = React.forwardRef<unknown, TextInterface>((props, _ref) => <Typography {...props} />)

export default Text
