const awards = [
  {
    logo: 'laur-konsumenta',
    title: 'lLur konsumenta',
    imgProps: {
      height: '56'
    }
  },
  {
    logo: 'rodzinna-marka-roku',
    title: 'Rodzinna Marka Roku',
    imgProps: {
      height: '56'
    }
  },
  {
    logo: 'gazele-biznesu',
    title: 'Gazele biznesu',
    imgProps: {
      height: '30'
    }
  },
  {
    logo: 'superbrands',
    title: 'Superbrands',
    imgProps: {
      height: '30'
    }
  }
]

const partnership = [
  {
    logo: 'iata',
    title: 'IATA'
  },
  {
    logo: 'pit',
    title: 'PIT'
  },
  {
    logo: 'rzetelna',
    title: 'Rzetelna firma',
    href: 'https://wizytowka.rzetelnafirma.pl/BusinessCard.aspx?banner=KUT0SXHK&source=1',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer nofollow'
    }
  }
]

const payments = [
  {
    logo: 'visa',
    title: 'Visa'
  },
  {
    logo: 'mastercard',
    title: 'Mastercard'
  },
  {
    logo: 'przelewy-24',
    title: 'Przelewy24'
  },
  {
    logo: 'six',
    title: 'Six Payments'
  }
]

const footerContent = [
  {
    title: 'Bezpieczeństwo płatności',
    items: payments,
    borderedLogos: true
  },
  {
    title: 'Jesteśmy członkiem',
    items: partnership,
    borderedLogos: true
  },
  {
    title: 'Nagrody i wyróżnienia',
    items: awards,
    borderedLogos: false
  }
]

export default footerContent
