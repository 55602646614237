import React from 'react'

import Button from 'elements/Button'
import { Heading, Wrapper, StyledParagraph, Form, StyledInput } from './styled'

const Newsletter = () => (
  <Wrapper>
    <Heading headerVariant='h3'>Zapisz się na newsletter Wakacje.pl</Heading>
    <StyledParagraph size='l'>
        My też kochamy dobre lasty! Zostaw nam swój e-mail, a będziemy podsyłać Ci najlepsze!
    </StyledParagraph>
    <Form action='https://www.wakacje.pl/newsletter.html' method='get'>
      <StyledInput name='email' placeholder='Podaj adres email' />
      <Button type='submit' size='large' themeVariant='primary'>
          Chcę się zapisać
      </Button>
    </Form>
  </Wrapper>
)

export default Newsletter
