import { createMuiTheme } from '@material-ui/core/styles'

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'Lato', 'sans-serif'].join(','),
    body1: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '24px',
      letterSpacing: '0.2px'
    },
    body2: {
      fontSize: '11px',
      fontWeight: 'normal',
      lineHeight: '24px',
      letterSpacing: '0.05em'
    },
    h1: {
      fontSize: '40px',
      fontWeight: 'bold',
      lineHeight: '48px'
    },
    h2: {
      fontSize: '28px',
      fontWeight: 'bold',
      lineHeight: '40px'
    },
    h3: {
      fontSize: '22px',
      fontWeight: 'bold',
      lineHeight: '32px'
    },
    h4: {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '24px'
    },
    h5: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '24px'
    },
    caption: {
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: '16px',
      letterSpacing: '0.3px'
    }
  },
  palette: {
    primary: {
      light: '#ccddf2',
      main: 'rgb(0,86,189)', // 0056BD
      dark: '#0056BC'
    },
    secondary: {
      light: 'rgb(255,240,192)', // fff0c0
      main: 'rgb(254,204,45)' // FECC2D
    },
    text: {
      primary: '#191919',
      secondary: '#595959'
    },
    background: {
      default: '#fff'
    },
    action: {
      focus: 'rgb(60, 180, 254)', // 3CB4FE
      focusOpacity: 0.12
    }
  },
  spacing: 8,
  overrides: {}
})

export default materialTheme
