import { Wak } from '../interfaces'

declare global {
  interface Window {
    wak: Wak
    Beamer: {
      notificationNumber?: number
    }
  }
}

/**
 * Invokes service method - and send analytics event corresponded to that method
 *
 * @param type Mehod from service to call, format: [SERVICE].[METHOD]
 * @param data Data to pass into event
 * @param isMobile Should pass data as mobile
 */
export const analytics = (type: string, data: any[], isMobile: boolean = true): void => {
  if (/pageView/.test(type) && data[0]) {
    if (isMobile) {
      data[0] = data[0].replace(/\//, window.wak.config.mobile ? '/m.' : '/')
    }
  }

  let analyticsData = data[0]
  if (type === 'ga.track') {
    const dimensions = {
      dimension65: analyticsData.dimension65 ? analyticsData.dimension65 : '',
      dimension64: analyticsData.dimension64 ? analyticsData.dimension64 : ''
    }
    analyticsData = { ...analyticsData, ...dimensions }
  }

  data[0] = analyticsData

  if (typeof window !== 'undefined' && typeof window.wak?.analytics?.run === 'function') {
    window.wak.analytics.run(type, data)
  }
}
