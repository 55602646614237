import React, { useContext, useEffect, useState } from 'react'
import MenuWidget from '@wakds/menu-widget'
import { CoreUserAccount } from '@wakds/user-account-widget'

import { ConsultantBar } from './styled'
import useUserManager from './hooks/useUserManager'
import useOfflineWidgetManagement from './hooks/useOfflineWidgetManagement'
import useUserAccountManager from './hooks/useUserAccountManager'
import useFetchContactNumber from 'components/Header/PhoneNumber/useFetchContactNumber'
import runtimeConfig from 'common/config'
import { userContext } from 'context/UserProvider/initialState'
import { analytics } from 'helpers/analytics'
import { useCheckElementHasLoaded } from './hooks/useCheckElementHasLoaded'

const { publicRuntimeConfig } = runtimeConfig

const Header = () => {
  const user = useContext(userContext)
  const { handleClickOfflineWidget, isOnline } = useOfflineWidgetManagement()
  const { handleClickElement, showToast } = useUserAccountManager()
  const { phoneNumber } = useFetchContactNumber()
  const isBeamer = useCheckElementHasLoaded('beamerSelector')
  const [beamer, setBeamer] = useState(0)
  useUserManager()

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.Beamer && window?.Beamer?.notificationNumber) {
      setBeamer(window.Beamer.notificationNumber)
    }
  }, [isBeamer])

  const innovationsProps = {
    isBeamer,
    count: beamer
  }

  const coreUserAccountComponent = (
    <CoreUserAccount
      email={user.email}
      isUserLoggedIn={user.isLoggedIn}
      nameFirstLetter={user.nameFirstLetter}
      manage1LoginUrl={publicRuntimeConfig.ONE_LOGIN_EDIT_PROFILE_LINK || ''}
      avatarUrl={user.avatarSrc}
      onLogout={handleClickElement}
      isOfflineMode={!isOnline}
      shouldRenderNextLinks
      shouldOpenLinksInNewWindow={false}
      onClickLink={showToast}
      isCheckVoucherBalanceFeatureDisabled={!publicRuntimeConfig.CHECK_VOUCHER_BALANCE_ENABLED}
    />
  )

  const clipboardProps = {
    onClickHandle: handleClickOfflineWidget,
    host: publicRuntimeConfig.WAK_API_HOST,
    url: publicRuntimeConfig.WAK_URL,
    disabled: !isOnline
  }

  return (
    <>
      {
        user.consultantLogin && (
          <ConsultantBar>
            Zalogowano jako konsultant na konto użytkownika: <b>{user.firstName}&nbsp;{user.lastName}</b> ({user.email})
          </ConsultantBar>
        )
      }
      <MenuWidget
        host='https://www.wakacje.pl'
        phoneNumber={phoneNumber}
        analytics={analytics}
        clipboardProps={clipboardProps}
        accountComponent={coreUserAccountComponent}
        innovationsProps={innovationsProps}
      />
    </>
  )
}

export default Header
