import { createGlobalStyle } from 'styled-components'

const ToastStyles = createGlobalStyle`
  .Toastify__toast {
      width: 500px;
      min-height: initial;
      padding: 0;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__toast-body {
      width: 100%;
  }
  .Toastify__progress-bar {
      visibility: hidden;
  }
  .Toastify__toast-container--bottom-right {
      right: 18em;
  }
  .Toastify__toast-container--top-right {
      right: 18em;
  }
  .Toastify__toast-container--bottom-center {
      margin-left: -85px;
  }
  .Toastify__toast-container--top-center {
      margin-left: -85px;
  }

  @media (max-width: 550px) {
    .Toastify__toast {
        width: 460px;
        margin: 10px;
    }
    .Toastify__toast-container--bottom-right {
        right: 15em;
    }
    .Toastify__toast-container--top-right {
        right: 15em;
    }
    .Toastify__toast-container--bottom-left {
        left: 0;
    }
    .Toastify__toast-container--top-left {
        left: 0;
    }
  }

  @media (max-width: 480px) {
    .Toastify__toast {
        width: initial;
        margin: 10px;
    }
    .Toastify__toast-container--bottom-right {
        right: 1em;
    }
    .Toastify__toast-container--top-right {
        right: 1em;
    }
    .Toastify__toast-container--bottom-center {
        margin-left: 0;
    }
    .Toastify__toast-container--top-center {
        margin-left: 0;
    }
  }
`

export default ToastStyles
