import { createContext } from 'react'

import { ViewContext } from './ViewContext.interface'

export const initialState: ViewState = {
  isAppWorkingOutOfBrowser: false,
  isOnline: false
}

export interface ViewState {
  isAppWorkingOutOfBrowser: boolean
  isOnline: boolean
}

export const viewContext = createContext(initialState as ViewContext)

export default viewContext.Provider
