import React from 'react'

import Link from 'elements/Link'

import companies from './companies'
import { StyledParagraph, Wrapper, StyledImage } from './styled'

const Partners = () => (
  <Wrapper>
    <StyledParagraph size='s'>Partnerzy</StyledParagraph>
    {companies.map(({ imgSrc, link }) => (
      <Link key={imgSrc} href={link}>
        <StyledImage src={imgSrc} />
      </Link>
    ))}
  </Wrapper>
)

export default Partners
