import React from 'react'

import MUIBox from '@material-ui/core/Box'
import { BoxProps } from '@material-ui/core'

interface Box extends BoxProps {
  children: React.ReactNode
}

const Box = ({ children, ...props }: Box) => <MUIBox {...props}>{children}</MUIBox>

export default Box
