import React, { VoidFunctionComponent } from 'react'

import Link from 'elements/Link'
import Box from 'elements/Box'

import { StyledText, StyledImage } from './CompanyLogos.styled'

export interface CompanyLogos {
  title: string
  items: {
    logo: string
    title: string
    href?: string
    props?: {
      [key: string]: string
    }
    imgProps?: {
      [key: string]: string
    }
  }[]
  borderedLogos?: boolean
}

const CompanyLogos:VoidFunctionComponent<CompanyLogos> = ({ title, items, borderedLogos }) => (
  <>
    <StyledText variant='body2'>
      {title}
    </StyledText>
    <Box>
      {items.map(({ logo, title, href, props, imgProps }) => {
        const Component = href ? Link : 'span'
        return (
          <Component key={logo} href={href} {...props}>
            <StyledImage
              $withBorder={Boolean(borderedLogos)}
              src={`/images/footer/${logo}.png`}
              alt={title}
              {...imgProps}
            />
          </Component>
        )
      })}
    </Box>
  </>
)

export default CompanyLogos
