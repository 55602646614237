import React, { FunctionComponent, ReactNode, useReducer } from 'react'
import { RemoveUserContext } from './Context'
import { initialState } from './initialState'
import { reducer } from './reducer'
import useHandleRemoveUser from './useHandleRemoveUser'

export interface RemoveUserProviderProps {
  children: ReactNode
}

const RemoveUserProvider: FunctionComponent<RemoveUserProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { handleRemoveUser } = useHandleRemoveUser(dispatch)

  return (
    <RemoveUserContext.Provider value={{ ...state, dispatch, handleRemoveUser }}>{children}</RemoveUserContext.Provider>
  )
}

export default RemoveUserProvider
