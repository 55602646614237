export interface InitialState {
  isRemoveModalOpened: boolean
  isSubmitting: boolean
  isRemoveError: boolean
  removeUserModalSection: {
    id: number
    title: string
    listItems: string[]
  }[]
}

export const initialState: InitialState = {
  isRemoveModalOpened: false,
  isSubmitting: false,
  isRemoveError: false,
  removeUserModalSection: [
    {
      id: 1,
      title: 'Co się stanie, gdy usuniesz konto w panelu Klienta:',
      listItems: [
        'stracisz szybki dostęp do swoich zamówień, dokumentów i szczegółów podróży,',
        'nie zobaczysz aktualnego statusu wpłat za zamówienia,',
        'stracisz dostęp do informacji o saldzie i terminie ważności Twoich bonów wakacyjnych'
      ]
    },
    {
      id: 2,
      title: 'Mimo zamknięcia konta:',
      listItems: [
        'poinformujemy Cię e-mailem o zbliającej się płatności za zamówienie,',
        'Twoje podróże nadal będą dostępne w naszej bazie, więc możesz do nich wrócić w dowolnym momenencie, zakładając ponownie konto i dodając je do listy zamówień'
      ]
    }
  ]
}
