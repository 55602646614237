import { NextPageContext, GetServerSidePropsContext } from 'next'
import fetch from 'isomorphic-unfetch'
import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'

import Method from './Method'
import parseOptions from './parseOptions'
import extendRequestBody from './extendRequestBody'

import { matchByList } from 'helpers/regexp'
import { unsetUserCookies, unsetRedirectURLCookie } from 'helpers/cookies'

import { ERROR_NAME, ROUTES } from 'constants/index'
import { RequestOptions } from './RequestOptions'
import runtimeConfig from 'common/config'

/*
  200 OK 'enabled'
  204 OK no-content
  401 Unauthorized niezalogowany
  403 Forbidden 'to_confirm'
  404 Not Found
  406 Not Acceptable 'disabled'
  423 Locked 'blocked'
  410 Gone 'deleted'
  451 Unavailable For Legal Reasons 'banned'

  block -> zablokowany czasowo / ma expiration date
  disabled -> wylaczony przez klienta
  banned -> zablokowany permanentnie przez nas lub automat
 */

interface Context extends NextPageContext {
  req: IncomingMessage
}

const request = <T>(
  url: string,
  options: RequestOptions = {
    method: Method.GET
  }
): Promise<T> => ssrRequest(undefined, url, options, runtimeConfig.publicRuntimeConfig.API_URL, false)

const putRequest = <T>(
  url: string,
  options: RequestOptions = {
    method: Method.PUT
  }
): Promise<T> => ssrRequest(undefined, url, options, runtimeConfig.publicRuntimeConfig.API_URL, false)

const ssoRequest = <T>(
  url: string,
  options: RequestOptions = {
    method: Method.GET
  }
): Promise<T> => ssrRequest(undefined, url, options, runtimeConfig.publicRuntimeConfig.SSO_URL, true)

const enovatisRequest = <T>(
  url: string,
  options: RequestOptions = {
    method: Method.GET
  }
): Promise<T> => ssrRequest(undefined, url, options, runtimeConfig.publicRuntimeConfig.ENOVATIS_URL)

const localApiRequest = <T>(
  url: string,
  options: RequestOptions = {
    method: Method.GET
  }
): Promise<T> => ssrRequest(undefined, url, options, runtimeConfig.publicRuntimeConfig.LOCAL_API_URL)

const ssrRequest = <T>(
  ctx: Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>,
  url: string,
  options: RequestOptions = {
    method: Method.GET
  },
  customApiUrl?: string | undefined,
  ignore401?: boolean | undefined,
  shouldRejectWithResponseWhenStatusCode400Occur?: boolean
): Promise<T> => {
  const domain = customApiUrl || runtimeConfig.publicRuntimeConfig.API_URL
  const redirect = (url: string) => {
    if (ctx) {
      if (ctx.res && !ctx.res.finished) {
        ctx.res.writeHead(307, { Location: url })
        ctx.res.end()
      }
    } else {
      window.location.replace(url)
    }
  }

  const requestUrl = `${domain}${url}`
  const currentUrl = ctx ? ctx.req.originalUrl : location.pathname

  if (options.body) {
    options.body = extendRequestBody(options.body, requestUrl)
  }

  return fetch(requestUrl, parseOptions(ctx, options))
    .then(async (response) => {
      switch (response.status) {
        case 400:
          if (shouldRejectWithResponseWhenStatusCode400Occur) {
            return Promise.reject(response)
          }
          return decorateRejectResponse(await response.text())
        case 401: // Unauthorized niezalogowany
          unsetUserCookies(ctx)
          unsetRedirectURLCookie(ctx)
          if (matchByList(ROUTES.ACTION_MAP.DO_NOT_RESTRICT, currentUrl)) {
            if (ignore401) {
              return Promise.resolve()
            }
            return Promise.reject(response)
          }
          redirect(ROUTES.REDIRECTS_MAP.WHEN_NOT_SIGNED_IN)
          break

        case 403: // Forbidden 'to_confirm'
          if (matchByList(ROUTES.ACTION_MAP.ALLOW_WHEN_UNCONFIRMED, currentUrl)) {
            // jezeli jestesmy na stronie z listy to odrzucamy...
            return decorateRejectResponse(await response.text())
          }
          // ... przekierowujemy na formularz potwierdzenia konta nowego użytkownika /form/confirm-agreements
          redirect(ROUTES.REDIRECTS_MAP.WHEN_UNCONFIRMED)
          break

        case 404: // Not Found
          if (matchByList(ROUTES.ACTION_MAP.DO_REDIRECT_WHEN_CONFIRMED, currentUrl)) {
            redirect(ROUTES.REDIRECTS_MAP.WHEN_CONFIRMED)
          }
          if (matchByList(ROUTES.ACTION_MAP.DO_REDIRECT_WHEN_DOESNT_EXISTS, currentUrl)) {
            redirect(ROUTES.REDIRECTS_MAP.WHEN_PAGE_NOT_FOUND)
          }
          return decorateRejectResponse(await response.text())

        case 200: // OK 'enabled'
        case 201: {
          if (requestUrl.includes('/logout')) {
            return response.status
          }

          if (!response.ok) {
            return Promise.reject(response)
          }

          const headers = response.headers
          const contentType = headers.get('Content-Type')
          if (contentType && (contentType.includes('application/json') || contentType.includes('application/vnd.api+json'))) {
            return response.json()
          } else if (contentType && contentType.includes('application/pdf')) {
            return response.blob()
          }
          return Promise.resolve()
        }
        case 204: {
          return Promise.resolve()
        }

        case 406:
          redirect(`${ROUTES.REDIRECTS_MAP.WHEN_WARNING}/406`)
          break
        case 423:
          redirect(`${ROUTES.REDIRECTS_MAP.WHEN_WARNING}/423`)
          break
        case 429:
          redirect(`${ROUTES.REDIRECTS_MAP.WHEN_WARNING}/429`)
          break
        case 451:
          redirect(`${ROUTES.REDIRECTS_MAP.WHEN_WARNING}/451`)
          break
        case 500: {
          console.error(`${response.statusText} ${response.url}`)
          return decorateRejectResponse(await response.text())
        }

        default: {
          return decorateRejectResponse(await response.text())
        }
      }
    })
    .catch((e) => Promise.reject(e))
}

// @todo ujednolicić zwrotkę z errorów i usunąć zmienną shouldRejectWithResponseWhenStatusCode400Occur
const decorateRejectResponse = function (responseText: string) {
  try {
    const responseBody = JSON.parse(responseText)

    if (responseBody.errors) {
      return Promise.reject(responseBody)
    }

    const e = new Error(responseBody.error || responseBody.message)
    e.name = responseBody.title || ERROR_NAME
    return Promise.reject(e)
  } catch (err) {
    const e = new Error('')
    e.name = 'Wystąpił nieoczekiwany błąd'
    return Promise.reject(e)
  }
}

export { request, putRequest, ssoRequest, ssrRequest, enovatisRequest, localApiRequest }
