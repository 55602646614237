import * as Sentry from '@sentry/nextjs'
import version from './version.json'

import runtimeConfig from 'common/config'

const NODE_ENV = process.env.NODE_ENV || 'development'
const SENTRY_ACTIVE = runtimeConfig.publicRuntimeConfig.SENTRY_ACTIVE
const SENTRY_DSN = runtimeConfig.publicRuntimeConfig.SENTRY_DSN
const APP_NAME = runtimeConfig.publicRuntimeConfig.APP_NAME

const { version: APP_VERSION } = version

const APP_RELEASE = `${APP_NAME}@${APP_VERSION}`

Sentry.init({
  enabled: SENTRY_ACTIVE,
  dsn: SENTRY_DSN,
  environment: NODE_ENV,
  release: APP_RELEASE,
  attachStacktrace: true,
  debug: false,
  tracesSampleRate: 0.4,
  sampleRate: 0.4
})
