const getConfig = require('next/config')
const ErrorPageLinksCollection = require('../types/ErrorPageLinksCollection')

// @todo konfiguracja pod obsługę TS
/*
import { NextConfig } from 'common/config/types'
const nextConfig: NextConfig = getConfig()
let nextPublicRuntimeConfig = <NextConfig['publicRuntimeConfig']>{}
*/

const nextConfig = getConfig.default()
let nextPublicRuntimeConfig

if (nextConfig) {
  const { publicRuntimeConfig: _publicRuntimeConfig } = nextConfig
  nextPublicRuntimeConfig = _publicRuntimeConfig
}

const getPublicRuntimeConfig = () => ({
  APP_NAME: nextPublicRuntimeConfig?.APP_NAME ?? process.env.APP_NAME,
  APP_URL: nextPublicRuntimeConfig?.APP_URL ?? process.env.APP_URL,
  REACT_APP_COOKIE_DOMAIN: nextPublicRuntimeConfig?.REACT_APP_COOKIE_DOMAIN ?? process.env.REACT_APP_COOKIE_DOMAIN,
  FEATURE_OFFLINE_SYNC:
    nextPublicRuntimeConfig?.FEATURE_OFFLINE_SYNC ?? Boolean(Number(process.env.FEATURE_OFFLINE_SYNC)),
  API_URL: nextPublicRuntimeConfig?.API_URL ?? process.env.API_URL,
  API_PREFIX: nextPublicRuntimeConfig?.API_PREFIX ?? process.env.API_PREFIX,
  SSO_URL: nextPublicRuntimeConfig?.SSO_URL ?? process.env.SSO_URL,
  REMOTE_NP_URL: nextPublicRuntimeConfig?.REMOTE_NP_URL ?? process.env.REMOTE_NP_URL,
  LOCAL_API_URL: nextPublicRuntimeConfig?.LOCAL_API_URL ?? process.env.LOCAL_API_URL,
  ENOVATIS_URL: nextPublicRuntimeConfig?.ENOVATIS_URL ?? process.env.ENOVATIS_URL,
  WAK_API_HOST: nextPublicRuntimeConfig?.WAK_API_HOST ?? process.env.WAK_API_HOST,
  WAK_URL: nextPublicRuntimeConfig?.WAK_URL ?? process.env.WAK_URL,
  ONE_LOGIN_AUTH_URL: nextPublicRuntimeConfig?.ONE_LOGIN_AUTH_URL ?? process.env.ONE_LOGIN_AUTH_URL,
  ONE_LOGIN_CALLBACK_URL: nextPublicRuntimeConfig?.ONE_LOGIN_CALLBACK_URL ?? process.env.ONE_LOGIN_CALLBACK_URL,
  ONE_LOGIN_REDIRECT_URL: nextPublicRuntimeConfig?.ONE_LOGIN_REDIRECT_URL ?? process.env.ONE_LOGIN_REDIRECT_URL,
  ONE_LOGIN_ERROR_REDIRECT_URL:
    nextPublicRuntimeConfig?.ONE_LOGIN_ERROR_REDIRECT_URL ?? process.env.ONE_LOGIN_ERROR_REDIRECT_URL,
  ONE_LOGIN_FLOW_SELECT_ACCOUNT:
    nextPublicRuntimeConfig?.ONE_LOGIN_FLOW_SELECT_ACCOUNT ?? process.env.ONE_LOGIN_FLOW_SELECT_ACCOUNT,
  ONE_LOGIN_FLOW_REGISTRATION:
    nextPublicRuntimeConfig?.ONE_LOGIN_FLOW_REGISTRATION ?? process.env.ONE_LOGIN_FLOW_REGISTRATION,
  ONE_LOGIN_EDIT_PROFILE_LINK:
    nextPublicRuntimeConfig?.ONE_LOGIN_EDIT_PROFILE_LINK ?? process.env.ONE_LOGIN_EDIT_PROFILE_LINK,
  AUTHORIZATION_TIME: nextPublicRuntimeConfig?.AUTHORIZATION_TIME ?? Number(process.env.AUTHORIZATION_TIME),
  GOOGLE_MAPS_KEY: nextPublicRuntimeConfig?.GOOGLE_MAPS_KEY ?? process.env.GOOGLE_MAPS_KEY,
  MOCK_SERVER_PORT: nextPublicRuntimeConfig?.MOCK_SERVER_PORT ?? Number(process.env.MOCK_SERVER_PORT),
  STORYBOOK_HOST: nextPublicRuntimeConfig?.STORYBOOK_HOST ?? process.env.STORYBOOK_HOST,
  SENTRY_ACTIVE: nextPublicRuntimeConfig?.SENTRY_ACTIVE ?? Boolean(Number(process.env.SENTRY_ACTIVE)),
  SENTRY_DSN: nextPublicRuntimeConfig?.SENTRY_DSN ?? process.env.SENTRY_DSN,
  PAYWALL_ENABLED: nextPublicRuntimeConfig?.PAYWALL_ENABLED ?? Boolean(Number(process.env.PAYWALL_ENABLED)),
  CHECK_VOUCHER_BALANCE_ENABLED:
    nextPublicRuntimeConfig?.CHECK_VOUCHER_BALANCE_ENABLED ??
    Boolean(Number(process.env.CHECK_VOUCHER_BALANCE_ENABLED)),
  ERROR_PAGE_LINKS:
    nextPublicRuntimeConfig?.ERROR_PAGE_LINKS ?? ErrorPageLinksCollection.createFromEnv(process.env.ERROR_PAGE_LINKS),
  BUY_VOUCHER_URL: nextPublicRuntimeConfig?.BUY_VOUCHER_URL ?? String(process.env.BUY_VUCHER_URL),
  FAQ_VOUCHER_URL:
    nextPublicRuntimeConfig?.FAQ_VOUCHER_URL ?? decodeURIComponent(String(process.env.FAQ_VOUCHER_URLENCODED_URL)),
  VOUCHER_CONTACT_EMAIL: nextPublicRuntimeConfig?.VOUCHER_CONTACT_EMAIL ?? process.env.VOUCHER_CONTACT_EMAIL,
  CDN_URL: nextPublicRuntimeConfig?.CDN_URL ?? process.env.CDN_URL
})

module.exports = getPublicRuntimeConfig()
