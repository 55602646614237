import React, { FunctionComponent, ReactNode } from 'react'

import Provider from './initialState'
import useUserStateManager from './useUserStateManager'
import { User } from '../../components/Header/types'

export interface UserProviderProps {
  initialUserContext?: User
  children: ReactNode
}

const UserProvider: FunctionComponent<UserProviderProps> = ({ children, initialUserContext }: UserProviderProps) => {
  const value = useUserStateManager(
    initialUserContext ? { ...initialUserContext, isOfflineUserData: false } : undefined
  )

  return (
    <Provider value={value}>
      {children}
    </Provider>
  )
}

export default UserProvider
