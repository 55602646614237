import { useEffect, useRef } from 'react'

const useComponentSubscription = () => {
  const isSubscribed = useRef(true)

  useEffect(() => () => {
    isSubscribed.current = false
  }, [])

  return isSubscribed
}

export default useComponentSubscription
