import React from 'react'

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './styled'
import { TypeOptions } from 'react-toastify'

interface Props {
  severity?: TypeOptions;
}

const SeverityIcon = ({ severity }: Props) => {
  switch (severity) {
    case 'info':
      return <InfoIcon />
    case 'error':
      return <ErrorIcon />
    case 'success':
      return <SuccessIcon />
    case 'warning':
      return <WarningIcon />
    default:
      return <></>
  }
}

export default SeverityIcon
