import { NextPageContext, GetServerSidePropsContext } from 'next'
import { setCookie } from 'nookies'
import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'

import dayjs from './dayjs'

import { COOKIES } from '../constants'
import isNextChunkPage from './isNextChunkPage'
import isImageRequest from './isImageRequest'
import isUrlAcceptable from './isUrlAcceptable'
import runtimeConfig from 'common/config'

interface Context extends NextPageContext {
  req: IncomingMessage
}

const setRedirectURLCookie = (
  context: NextPageContext | Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>
) => {
  const urlToRedirect = context?.req?.originalUrl
  if (
    urlToRedirect &&
    !isNextChunkPage(urlToRedirect) &&
    !isImageRequest(urlToRedirect) &&
    isUrlAcceptable(urlToRedirect)
  ) {
    setCookie(
      context,
      COOKIES.REDIRECT_URL,
      urlToRedirect,
      {
        domain: runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
        path: '/',
        expires: dayjs().add(1, 'day').toDate()
      }
    )
  }
}

export default setRedirectURLCookie
