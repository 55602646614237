import styled from 'styled-components'

import { Typography } from '@material-ui/core'

interface Props {
  size: 'xl' | 'l' | 'm' | 's'
  $renderAsListItem: boolean
  $isItalic: boolean
  $isBold: boolean
  $noPaddingBottom: boolean
}

export const StyledTypography = styled(Typography).attrs<Props>(props => ({
  component: props.$renderAsListItem ? 'li' : 'p',
  size: props.size,
  $isItalic: props.$isItalic,
  $isBold: props.$isBold,
  $noPaddingBottom: props.$noPaddingBottom
}))<Props>`
  max-width: 632px;
  ${({ theme, size, $isItalic, $isBold, $noPaddingBottom }) => `
    padding-bottom: ${$noPaddingBottom ? '0' : '16px'};
    font-weight: ${$isBold ? 'bold' : 'normal'};
    font-style: ${$isItalic ? 'italic' : 'normal'};
    color: ${theme.colors.gray600};
    ${size === 'xl' ? `      
        font-size: 20px;
        line-height: 32px;
      ` : size === 'l' ? `      
        font-size: 16px;
        line-height: 24px;
      ` : size === 'm' ? `      
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.2px;
      ` : size === 's' ? `      
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
      ` : ''
    }
  `}
`
