import React from 'react'
import Svg from './BaseIcon'

const ChevronLeft = (props) => (
  <Svg width='16' height='16' fill='none' viewBox='0 0 16 16' {...props}>
    <path d='M12 3.4L10.5 2 4 8l6.5 6 1.5-1.4L7 8l5-4.6z' fill='currentColor' />
  </Svg>
)

export default ChevronLeft
