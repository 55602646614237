import CacheName from 'enums/CacheName'

const addToCache = async (cacheName: CacheName, urls: string | string[]) => {
  try {
    const myCache = await window.caches.open(cacheName)
    if (Array.isArray(urls)) {
      await myCache.addAll(urls)
    } else {
      await myCache.add(urls)
    }
  } catch (e) {
    console.log(e)
  }
}

export default addToCache
