import React from 'react'

import Grid from '@material-ui/core/Grid'
import { GridProps } from '@material-ui/core'

interface Row extends GridProps {
  children: React.ReactNode
}

const Row = ({ children, ...props }: Row) => (
  <Grid container {...props}>
    {children}
  </Grid>
)

export default Row
