import { setCookie } from 'nookies'

import dayjs from 'helpers/dayjs'
import { COOKIES } from 'constants/index'
import runtimeConfig from 'common/config'

const createRefreshPreCacheCookie = () => {
  setCookie(
    null,
    COOKIES.PRECACHE_REFRESH_COOLDOWN,
    'true',
    {
      expires: dayjs().add(5, 'minute').toDate(),
      domain: runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
      path: '/'
    }
  )
}

export default createRefreshPreCacheCookie
