import React from 'react'

import Row from 'elements/Row'

import Copyright from './Copyright'
import CompanyLogos, { CompanyLogos as FooterContent } from './CompanyLogos/CompanyLogos'
import { StyledCol, StyledContainer, Wrapper } from './styles'
import footerContent from './footerContent'

const Footer = () => {
  const renderFooterContent = (content: FooterContent, index: number) => {
    const { title, items, borderedLogos } = content

    return (
      <StyledCol key={`${title}-${index}`}>
        <CompanyLogos title={title} items={items} borderedLogos={borderedLogos} />
      </StyledCol>
    )
  }

  return (
    <Wrapper>
      <StyledContainer maxWidth='lg'>
        <Row justify='space-between'>
          {footerContent.map(renderFooterContent)}
        </Row>
      </StyledContainer>
      <Copyright />
    </Wrapper>
  )
}

export default Footer
