import { FormControlLabel, makeStyles, Theme, withStyles } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignSelf: 'flex-start',
    padding: theme.spacing(0.5, 1)
  },
  icon: {
    border: '1px solid #999',
    borderRadius: '2px',
    width: 16,
    height: 16,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    'input:hover ~ &': {
      border: `1px solid ${theme.reBrandingColors.blue800}`
    },
    'input:disabled ~ &': {
      background: '#f2f2f2'
    }
  },
  checkedIcon: {
    border: 'none',
    backgroundColor: theme.reBrandingColors.blue800,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' " +
        "viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' " +
        "d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' " +
        "fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      border: 'none',
      backgroundColor: theme.reBrandingColors.blue800
    }
  },
  labelInner: {
    display: 'inline'
  },
  labelInnerBolded: {
    fontWeight: 'bold',
    color: theme.palette.text.primary
  }
}))

export const StyledLabel = withStyles((theme: Theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.02em',
    color: theme.palette.text.secondary,
    '& span, a': {
      color: theme.reBrandingColors.blue800,
      textDecoration: 'none',
      transition: '0.5s',
      '&:hover': {
        color: theme.reBrandingColors.blue800
      }
    }
  }
}))(FormControlLabel)

export const RedSpan = styled.span`
  color: #EE0100 !important;
  margin-right: 5px;
`
