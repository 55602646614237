import React, { ReactNode } from 'react'

import ArrowRight from 'elements/Icons/ArrowRight'
import useActiveElementManager from './useActiveElementManager'
import useAccountItem from './useAccountItem'
import { ArrowWrapper, Element } from './styled'

interface MyAccountNavigationItem {
  icon: ReactNode
  title: string
  link: string
  isDisabledOffline: boolean
  isDisabled: boolean
  activeOnPages?: string[]
}

const MyAccountNavigationItem = ({
  icon,
  title,
  link,
  activeOnPages,
  isDisabledOffline,
  isDisabled: isNavItemDisabled
}: MyAccountNavigationItem) => {
  const { handleLogout, showToast, isDisabled } = useAccountItem(isDisabledOffline, link, title)
  const { isActiveElement } = useActiveElementManager(activeOnPages)

  if (isNavItemDisabled) return null

  if (link === '/logout') {
    return (
      <div onClick={showToast}>
        <Element as='span' onClick={handleLogout} $active={isActiveElement} $isDisabled={isDisabled}>
          {icon}
          {title}
          <ArrowWrapper>
            <ArrowRight />
          </ArrowWrapper>
        </Element>
      </div>
    )
  } else {
    return (
      <div onClick={showToast}>
        <Element href={link || '/'} $active={isActiveElement} $isDisabled={isDisabled}>
          {icon}
          {title}
          <ArrowWrapper>
            <ArrowRight />
          </ArrowWrapper>
        </Element>
      </div>
    )
  }
}

export default MyAccountNavigationItem
