import { createGlobalStyle } from 'styled-components'

export const BaseCSS = ` 
  #beamerSelector {
    display: none;
  }
  
  #beamerNews, #beamerLoader, #beamerOverlay {
    transition-delay: 1s;
  }

  #beamerNews {
    right: 0;
    left: auto;
  }
  
`

export const BaseStyles = createGlobalStyle`${BaseCSS}`
