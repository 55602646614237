import { useState, ChangeEvent } from 'react'

const useCheckboxChange = (onCheckboxChange?: ((e: ChangeEvent<HTMLInputElement>) => void)) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setIsChecked(checked)
    onCheckboxChange && onCheckboxChange(e)
  }

  const handleButtonClick = () => {
    setIsChecked(!isChecked)
  }

  return {
    isChecked,
    handleCheckboxChange,
    handleButtonClick
  }
}

export default useCheckboxChange
