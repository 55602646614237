import { useContext, useEffect } from 'react'

import preCacheOrderData from './utils/preCacheOrderData'

import { userContext } from 'context/UserProvider/initialState'

import addToCache from 'helpers/addToCache'
import { getCookie, unsetCookie } from 'helpers/cookies'
import cleanUpPreCacheData from 'helpers/cleanUpPreCacheData'

import CacheName from 'enums/CacheName'

import { COOKIES } from 'constants/index'
import { viewContext } from '../../context/ViewProvider/initialState'

const usePreCacheManager = () => {
  const { isLoggedIn, isOfflineSyncEnabled } = useContext(userContext)
  const { isOnline } = useContext(viewContext)

  useEffect(
    () => {
      if (isOfflineSyncEnabled && window.caches) {
        const isPreCacheCooldown = getCookie(null, COOKIES.PRECACHE_REFRESH_COOLDOWN)
        if (
          isLoggedIn &&
          !isPreCacheCooldown
        ) {
          preCacheOrderData()
          addToCache(CacheName.PreCachePages, '/order-list')
        }
      } else {
        unsetCookie(null, COOKIES.PRECACHE_REFRESH_COOLDOWN)
      }
    },
    [isLoggedIn]
  )

  useEffect(
    () => {
      try {
        if (!isOfflineSyncEnabled && isOnline) {
          cleanUpPreCacheData()
        }
      } catch (e) {
        console.log(e)
      }
    },
    [isOfflineSyncEnabled, isOnline]
  )
}

export default usePreCacheManager
