import { useCallback, useState } from 'react'

import { initialState, OneLoginState } from './initialState'

const useOneLoginStateManager = () => {
  const [state, setState] = useState(initialState)

  const handleStateChange = useCallback(
    (data: OneLoginState) => {
      setState({
        ...state,
        ...data
      })
    },
    [state, setState]
  )

  const clearState = useCallback(() => {
    setState({ ...initialState })
  }, [setState])

  return {
    ...state,
    handleStateChange,
    clearState
  }
}

export default useOneLoginStateManager
