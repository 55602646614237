import { NextPageContext, GetServerSidePropsContext } from 'next'
import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'
import { parseCookies, setCookie } from 'nookies'
import runtimeConfig from 'common/config'

import dayjs from './dayjs'
import { COOKIES } from '../constants'

interface Context extends NextPageContext {
  req: IncomingMessage
}

export const prolongeCookie = (
  ctx: Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>,
  name: string,
  value: string,
  expires: Date
) => {
  if (ctx) {
    setCookie(ctx, name, value, {
      domain: runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
      path: '/',
      expires: expires
    })
  } else if (undefined !== document && undefined !== document.cookie) {
    document.cookie = `${name}=${value}; expires=${expires}; domain=${runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN}; path=/`
  }
}

export const getCookie = (
  ctx: NextPageContext | Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>,
  name: string
): string => {
  if (ctx) {
    const cookie = parseCookies(ctx)[name]
    return undefined !== cookie ? cookie : ''
  } else {
    let cookie = ''
    if (typeof document !== 'undefined') {
      if (undefined !== document && undefined !== document.cookie) {
        cookie = parseCookies()[name]
      }
    }

    return cookie
  }
}

export const unsetCookie = (
  ctx: NextPageContext | Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>,
  name: string
) => {
  setCookie(ctx, name, '', {
    domain: runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
    path: '/',
    expires: dayjs().toDate()
  })
}

export const unsetUserCookies = (
  ctx: NextPageContext | Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>
) => {
  const cookiesToUnset: Array<string> = [
    COOKIES.CUSTOMER_ID,
    COOKIES.AUTH_TOKEN,
    COOKIES.AUTH_DURATION,
    COOKIES.AUTH_EXPIRES,
    COOKIES.USER_DATA,
    COOKIES.EM_ID,
    COOKIES.CONSULTANT_LOGIN
  ]

  cookiesToUnset.forEach((cookieName: string) => unsetCookie(ctx, cookieName))
}

export const unsetRedirectURLCookie = (ctx: NextPageContext | Context | null | undefined | GetServerSidePropsContext) =>
  unsetCookie(ctx, COOKIES.REDIRECT_URL)
