/* eslint-disable max-len */

import React from 'react'
import Svg from '../BaseIcon'

const Facebook = (props) => (
  <Svg width='9' height='17' viewBox='0 0 9 17' fill='none' {...props}>
    <path fill='currentColor' d='M2.044 3.205v2.278H.315V8.27h1.73v8.28h3.551V8.27H7.98s.223-1.335.331-2.796H5.61V3.568c0-.285.387-.668.77-.668h1.935V0h-2.63c-3.728 0-3.64 2.789-3.64 3.205' />
  </Svg>
)

export default Facebook
