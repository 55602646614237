import styled from 'styled-components'

import Header from 'styleguide/typography/Header'
import Paragraph from 'styleguide/typography/Paragraph'

import Input from 'elements/Input/Input'

export const Wrapper = styled.div`
  background: #f0f0f0;
  ${({ theme }) => `
    padding: ${theme.spacing(8, 0, 11)};
    text-align: center;
  `}
`

export const Heading = styled(Header)`
  max-width: 100%;
  margin: 0;
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
  `}
`

export const StyledParagraph = styled(Paragraph)`
  max-width: 100%;
  ${({ theme }) => `
    color: ${theme.palette.text.secondary};
    margin: ${theme.spacing(1, 0)};
  `}
`

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  & > div {
    margin: 0;
  }
  
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
        && button {
          margin-top: 24px;
        }
    }
  `}
`

export const StyledInput = styled(Input)`
  width: 287px;
  ${({ theme }) => `
    margin: ${theme.spacing(0, 2, 0, 0)};
  `}
`
