import { useEffect, useState } from 'react'

export const useCheckElementHasLoaded = (elementId: string) => {
  const [isElementReady, setIsElementReady] = useState(false)

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const beamerElement = document.getElementById(elementId)
      if (beamerElement) {
        setIsElementReady(true)
        observer.disconnect()
      }
    })
    observer.observe(document.body, { childList: true, subtree: true })
    return () => {
      observer.disconnect()
    }
  }, [elementId])
  return isElementReady
}
