import { MouseEvent, useCallback, useState } from 'react'

const useTextVisibility = (
  label: string | undefined,
  fullText: string | undefined
) => {
  const [visibleText, setVisibleText] = useState(label)
  const [isVisibleMoreButton, setIsVisibleMoreButton] = useState(!!fullText)

  const showFullText = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setVisibleText(fullText)
      setIsVisibleMoreButton(false)
    },
    [setVisibleText, isVisibleMoreButton, setIsVisibleMoreButton, fullText]
  )

  const hideFullText = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setVisibleText(label)
      setIsVisibleMoreButton(true)
    },
    [setVisibleText, isVisibleMoreButton, setIsVisibleMoreButton, label]
  )

  return {
    visibleText,
    isVisibleMoreButton,
    showFullText,
    hideFullText
  }
}

export default useTextVisibility
