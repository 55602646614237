import styled from 'styled-components'
import Link from 'elements/Link'

export const Element = styled(Link)<{ $active?: boolean; $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  ${({ theme }) => `
    border-bottom: 1px solid ${theme.reBrandingColors.gray100};
  `}
  transition: 0.3s;
  position: relative;
  ${({ theme, $active }) => `
    font-size: 14px;
    font-weight: ${$active ? 600 : 400};
    padding: ${theme.spacing(3)}px;
    background: ${$active ? `${theme.reBrandingColors.blue100}` : `${theme.palette.common.white}`};
    border-radius: ${$active ? '4px' : '0'};
    color: ${$active ? `${theme.reBrandingColors.gray800}` : `${theme.reBrandingColors.gray600}`};
    svg{
      transition: 0.3s;
      color: ${$active ? `${theme.reBrandingColors.blue800}` : `${theme.reBrandingColors.gray200}`};
    }
    & > svg {
      width: 20px;
      margin-right: ${theme.spacing(2)}px;
      color: ${$active ? `${theme.reBrandingColors.blue800}` : `${theme.reBrandingColors.gray800}`};
    }
    &:hover {
      background: ${theme.reBrandingColors.blue100};
      border-radius: 4px;
      border-bottom: 1px solid transparent;
      color: ${theme.reBrandingColors.blue800};
      svg {
        color: ${theme.reBrandingColors.blue800}
      }
    }
  `}
  ${({ theme, $isDisabled }) => $isDisabled && `
    color: ${theme.colors.gray200};
    pointer-events: none;
    & > svg {
      color: ${theme.colors.gray200};
    }
  `}
`

export const ArrowWrapper = styled.div`
  position: absolute;
  top: calc(50% - 7px);
  right: 28px;
  width: auto;
  svg {
    width: 14px;
    height: 14px;
  }
`
