import { Theme, useMediaQuery } from '@material-ui/core'

import useFetchDataManagement from 'helpers/useFetchDataManagement'
import { enovatisRequest } from 'helpers/request'

const useFetchPhoneNumberManagement = () => {
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const getPhoneNumberEndpoint = isMobileView
    ? '/dictionaries/getDictionary/INFO_0XX_MWAK_REACT_INO'
    : '/dictionaries/getDictionary/INFO_0XX_WAK_REACT_INO'

  const [phoneNumberResponse] = useFetchDataManagement<{ result: string[] }>(
    getPhoneNumberEndpoint,
    { result: [''] },
    { dependencyList: [isMobileView], overrideRequestMethod: enovatisRequest }
  )

  return {
    phoneNumber: phoneNumberResponse.result[0]
  }
}

export default useFetchPhoneNumberManagement
