import React, { ReactNode, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { StyledImage } from 'components/Footer/CompanyLogos/CompanyLogos.styled'
import Paragraph from 'styleguide/typography/Paragraph/Paragraph'
import Button from 'elements/Button'
import { ErrorIcon } from 'elements/Snackbar/SeverityIcon/styled'
import { RemoveUserContext } from 'context/RemoveUserProvider/Context'

const Container = styled.div`
  text-align: center;
`

const animation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`

export const Spinner = styled.div`
  animation: ${animation} 1.5s infinite linear;
  text-align: center;
  margin-bottom: 15px;
`

const ButtonWrapper = styled.div`
  padding: 10px;
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid ${theme.reBrandingColors.gray200};
    }
  `}
`

const RemoveUserLayout = ({ children }: { children: ReactNode }) => {
  const { isSubmitting, isRemoveError, dispatch } = useContext(RemoveUserContext)

  if (isSubmitting) {
    return (
      <Container>
        <Spinner
          onClick={() => {
            dispatch({ type: 'handleRemoveError' })
          }}
        >
          <StyledImage $withBorder={false} src='/images/modals/spinner.png' alt='Usuń konto' />
        </Spinner>
        <Paragraph isBold noPaddingBottom size='m'>
          Usuwamy twoje konto
        </Paragraph>
      </Container>
    )
  } else if (isRemoveError) {
    return (
      <Container>
        <ErrorIcon />
        <Paragraph isBold size='xl'>
          Ups, coś poszło nie tak
        </Paragraph>
        <Paragraph noPaddingBottom size='s'>
          Niestety nie udało nam się usunąć Twojego konta.
        </Paragraph>
        <Paragraph size='s'>Kliknij "Usuń konto", aby spróbować jeszcze raz.</Paragraph>
        <ButtonWrapper>
          <Button
            size='large'
            themeVariant='primary'
            onClick={() => dispatch({ type: 'handleRemoveAfterError' })}
            fullWidth
          >
            Usuń konto
          </Button>
        </ButtonWrapper>
      </Container>
    )
  } else {
    return <>{children}</>
  }
}

export default RemoveUserLayout
