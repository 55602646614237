const mobileMixins = {
  headers: {
    h1: 'font-size: 28px; line-height: 40px;',
    h2: 'font-size: 28px; line-height: 40px;',
    h3: 'font-size: 22px; line-height: 32px;',
    h4: 'font-size: 18px; line-height: 24px;',
    h5: 'font-size: 16px; line-height: 24px;',
    eyebrow: 'font-size: 16px; line-height: 24px; letter-spacing: 1.33px;'
  }
}

export default mobileMixins
