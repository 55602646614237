import React from 'react'

import Row from 'elements/Row'

import Facebook from 'elements/Icons/Footer/Facebook'
import Instagram from 'elements/Icons/Footer/Instagram'
import Twitter from 'elements/Icons/Footer/Twitter'

import lists from './listsItems'
import useFetchPhoneNumberManagement from './useFetchPhoneNumberManagment'
import { StyledCol, Wrapper, StyledParagraph, ListItem, AddressWrapper, ContactParagraph, BorderedIcon } from './styled'

const Lists = () => {
  const { phoneNumber } = useFetchPhoneNumberManagement()

  return (
    <Wrapper>
      <Row>
        {lists.map(({ title, elements }, index) => (
          <StyledCol key={`${title}-${index}`} xs={6} md={3}>
            <StyledParagraph size='s'>{title}</StyledParagraph>
            {elements.map(({ name, link }, index) => (
              <ListItem key={`${name}-${index}`} href={link}>
                {name}
              </ListItem>
            ))}
          </StyledCol>
        ))}
        <StyledCol xs={6} md={3}>
          <StyledParagraph size='s'>Kontakt</StyledParagraph>
          <AddressWrapper>
            <ContactParagraph size='m'>Wakacje.pl S.A.</ContactParagraph>
            <ContactParagraph size='m'>al. Grunwaldzka 413</ContactParagraph>
            <ContactParagraph size='m'>80-309 Gdańsk</ContactParagraph>
          </AddressWrapper>
          <ContactParagraph size='m'>telefon: {phoneNumber}</ContactParagraph>
          <BorderedIcon>
            <Facebook />
          </BorderedIcon>
          <BorderedIcon>
            <Instagram />
          </BorderedIcon>
          <BorderedIcon>
            <Twitter />
          </BorderedIcon>
        </StyledCol>
      </Row>
    </Wrapper>
  )
}

export default Lists
