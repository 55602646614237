import styled from 'styled-components'

import CloseIcon from '@material-ui/icons/Close'
import Paragraph from '../../../styleguide/typography/Paragraph'
import { TypeOptions } from 'react-toastify/dist/types'

export const Wrapper = styled.div<{ $severity?: TypeOptions; $isLightTheme?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme, $isLightTheme }) => `
     padding: ${theme.spacing(2)}px;
     background: ${$isLightTheme ? theme.colors.white : theme.colors.blue800};
  `}
  ${({ theme, $severity }) => $severity === 'error' && `
     border-left: 4px solid ${theme.colors.red400};
  `}
  ${({ theme, $severity }) => $severity === 'success' && `
     border-left: 4px solid ${theme.colors.green400};
  `}
  ${({ theme, $severity }) => $severity === 'warning' && `
     border-left: 4px solid ${theme.colors.yellow400};
  `}
  ${({ theme, $severity }) => $severity === 'info' && `
     border-left: 4px solid ${theme.colors.blue400};
  `}
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled(Paragraph)<{ $isLightTheme?: boolean }>`
  color: ${({ theme, $isLightTheme }) => $isLightTheme ? theme.colors.gray800 : theme.colors.white};
  padding: 0;
  word-break: break-word;
`

export const Text = styled(Paragraph)<{ $isLightTheme?: boolean }>`
  color: ${({ theme, $isLightTheme }) => $isLightTheme ? theme.colors.gray800 : theme.colors.gray200};
  padding: 0;
  word-break: break-word;
`

export const StyledCloseIcon = styled(CloseIcon)<{ $isLightTheme?: boolean }>`
  cursor: pointer;
  ${({ theme, $isLightTheme }) => `
    color: ${$isLightTheme ? theme.colors.gray800 : theme.colors.white};
    margin-left: ${theme.spacing(2)}px;
    ${theme.breakpoints.down('sm')} {
      align-self: flex-start;
    }
  `}
`
