import { toast as toastApi } from 'react-toastify'
import Toast from './Toast'
import React from 'react'
import ToastConfig from './ToastConfig'

const toast = {
  ...toastApi,
  success: (title: string, message: string, config?: ToastConfig) => toastApi.success(
    (props) => <Toast text={message} title={title} isLightTheme={config?.isLightTheme} {...props} />,
    config
  ),
  warning: (title: string, message: string, config?: ToastConfig) => toastApi.warning(
    (props) => <Toast text={message} title={title} isLightTheme={config?.isLightTheme} {...props} />,
    config
  ),
  error: (title: string, message: string, config?: ToastConfig) => toastApi.error(
    (props) => <Toast text={message} title={title} isLightTheme={config?.isLightTheme} {...props} />,
    config
  ),
  info: (title: string, message: string, config?: ToastConfig) => toastApi.info(
    (props) => <Toast text={message} title={title} isLightTheme={config?.isLightTheme} {...props} />,
    config
  )
}

export default toast
