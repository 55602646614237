import { Theme, withStyles } from '@material-ui/core'
import MUIInputLabel from '@material-ui/core/InputLabel'

export const StyledInputLabel = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: theme.palette.text.secondary,
    transform: 'translate(0, 4px) scale(1)',
    '&.Mui-focused': {
      color: theme.palette.text.secondary
    },
    position: 'relative',
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}))(MUIInputLabel)
