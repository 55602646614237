import { createContext } from 'react'

import { UserContext } from './UserContext.interface'

import { User } from 'components/Header/types'

export const initialState: UserState = {
  firstName: '',
  lastName: '',
  avatarSrc: '',
  email: '',
  isLoggedIn: false,
  nameFirstLetter: '',
  consultantLogin: '',
  isConfirmed: false,
  isOfflineSyncEnabled: false,
  isOfflineUserData: false
}

export interface UserState extends User {
  isOfflineUserData: boolean
}

export const userContext = createContext(initialState as UserContext)

export default userContext.Provider
