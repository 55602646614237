import styled from 'styled-components'

export const StyledLink = styled.a`
  text-decoration: none;
  color: 'inherit';
  line-height: 24px;
  display: inline-block;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
