const companies = [
  {
    imgSrc: '/images/footer/partners/itaka@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/itaka/'
  },
  {
    imgSrc: '/images/footer/partners/neckermann@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/neckermann'
  },
  {
    imgSrc: '/images/footer/partners/coral@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/coral-travel/'
  },
  {
    imgSrc: '/images/footer/partners/wezyr@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/coral-travel/'
  },
  {
    imgSrc: '/images/footer/partners/rainbow-tours@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/rainbow-tours/'
  },
  {
    imgSrc: '/images/footer/partners/sun-fun@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/sun-fun/'
  },
  {
    imgSrc: '/images/footer/partners/ecco-holiday@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/ecco-holiday/'
  },
  {
    imgSrc: '/images/footer/partners/exim-tours@1x.jpg',
    link: 'https://www.wakacje.pl/biuro/exim-tours/'
  }
]

export default companies
