import { useEffect, useState } from 'react'

import { Wak } from 'interfaces'
import { getPhoneNumber } from '@wakacje/wak-helpers/lib/newAnalytics/utils/getPhoneNumber'
import { getSourceMedium } from '@wakacje/wak-helpers/lib/newAnalytics/utils/getSourceMedium'

declare global {
  interface Window {
    wak: Wak
    dataLayer: any[]
  }
}

const useFetchContactNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  useEffect(() => {
    let phoneNumberToFetch

    if (typeof window === 'undefined') {
      phoneNumberToFetch = undefined
    } else {
      phoneNumberToFetch = window.wak ? getPhoneNumber(getSourceMedium(), undefined, undefined, undefined) : ''
    }

    setPhoneNumber(phoneNumberToFetch)
  }, [])

  return {
    phoneNumber
  }
}

export default useFetchContactNumber
