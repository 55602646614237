import useUserAccountManager from 'components/Header/hooks/useUserAccountManager'
import { COOKIES } from 'constants/index'
import { getCookie } from 'helpers/cookies'
import { putRequest } from 'helpers/request'
import { Dispatch } from 'react'
import { RemoveUserActions } from './actions'

const useHandleRemoveUser = (dispatch: Dispatch<RemoveUserActions>) => {
  const { handleLogoutAfterRemove } = useUserAccountManager()
  const customerId = getCookie(undefined, COOKIES.CUSTOMER_ID)

  const handleRemoveUser = async () => {
    dispatch({ type: 'toggleSubmitting', isSubmitting: true })

    await putRequest(`/customers/${customerId}/anonymize`)
      .then(() => {
        handleLogoutAfterRemove()
      })
      .catch(() => {
        dispatch({ type: 'handleRemoveError' })
      })
  }

  return {
    handleRemoveUser,
    customerId
  }
}

export default useHandleRemoveUser
