import styled from 'styled-components'

import Container from 'elements/Container/Container'
import Col from 'elements/Col/Col'
import Text from 'elements/Text'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: auto;
  overflow-x: hidden;
`

export const Content = styled(Container)<{ $isOnTop?: boolean; $isAddOrderView: boolean; $isOfflineContent: boolean }>`
  flex-grow: 1;
  max-width: 1140px;
  ${({ $isOfflineContent }) =>
    $isOfflineContent &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
  ${({ theme, $isAddOrderView, $isOnTop }) => `
    padding: ${theme.spacing(9, 0)};
    ${theme.breakpoints.down('md')} {
      padding: ${$isAddOrderView ? theme.spacing(4, 2) : theme.spacing(9, 2)};
    }
    ${theme.breakpoints.down('xs')} {
      padding: 0;
      ${
        $isOnTop &&
        `
        position: absolute;
        background: white;
        background-color: white;
        width: 100vw;
        height: 100vh;
        z-index: 1;
      `
      }
    }
  `};
`

export const Label = styled.span`
  font-size: 10px;
  padding: 6px 8px;
  border-radius: 2px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
  ${({ theme }) => `
    background-color: ${theme.colors.yellow200};
  `}
  color: white;
`

export const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`

export const LeftColumn = styled(Col)`
  flex: 0 0 410px;
  max-width: 410px;
  ${({ theme }) => `
    padding-right:  ${theme.spacing(8)}px;
    ${theme.breakpoints.down('md')} {
      margin-bottom: ${theme.spacing(4)}px;
    }
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `}
`

export const RightColumn = styled(Col)<{ $showMyAccountNavigation: boolean }>`
  ${({ theme, $showMyAccountNavigation }) =>
    $showMyAccountNavigation
      ? `
  flex: 0 0 calc(100% - 410px);
  max-width: calc(100% - 410px);
    ${theme.breakpoints.down('sm')} {
      flex: 0 0 100%;
      max-width: 100%;
    }
  `
      : `
    flex: 0 0 100%;
    max-width: 100%;
  `}
`

export const Heading = styled(Text)`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(8)}px;
  `}
`
