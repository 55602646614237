import React from 'react'
import NextApp, { AppContext, AppInitialProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import { setCookie } from 'nookies'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { StylesProvider } from '@material-ui/core'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/styles'
import '@commonds/react/style.css'
import '@commonds/tokens/generated/wak-desktop-tokens/css/variables.css'
import '@commonds/tokens/generated/wak-desktop-tokens/css/wak/variables-wak.css'

import { User } from 'components/Header/types'
import AppProvider from 'components/AppProvider'

import { redirectFromRestricted } from 'helpers/accessControl'
import { getCookie } from 'helpers/cookies'
import { GTMPageView } from 'helpers/gtm'
import dayjs from 'helpers/dayjs'
import theme from 'helpers/theme'

import { COOKIES, MAX_COOKIE_DATE } from '../constants'
import runtimeConfig from 'common/config'

interface AppProps extends AppInitialProps {
  isErrorPage: boolean
  userData: User
}

class App extends NextApp<AppProps> {
  componentDidMount () {
    GTMPageView(window.location.pathname)

    if (document !== undefined) {
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
      Router.events.on('routeChangeComplete', this.handleRouteChange)
    }
  }

  componentWillUnmount () {
    Router.events.off('routeChangeComplete', this.handleRouteChange)
  }

  componentDidCatch (error: Error) {
    throw error
  }

  handleRouteChange = (url: string) => {
    GTMPageView(url)
  }

  render () {
    const { Component, pageProps, isErrorPage, userData } = this.props

    return (
      <>
        <Head>
          <title>Panel klienta</title>
          <meta
            name='viewport'
            content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
          />
        </Head>
        <StylesProvider injectFirst>
          <StyledThemeProvider theme={theme}>
            <MaterialThemeProvider theme={theme}>
              <AppProvider
                userData={userData}
                isErrorPage={isErrorPage}
              >
                <Component {...pageProps} />
              </AppProvider>
            </MaterialThemeProvider>
          </StyledThemeProvider>
        </StylesProvider>
      </>
    )
  }
}

App.getInitialProps = async (appContext: AppContext) => {
  // @todo: tymczasowa flaga na czas testow
  const { offlinecache } = appContext.ctx.query
  let offlineModeOverride = false
  if (offlinecache) {
    offlineModeOverride = offlinecache === 'on'
    setCookie(
      appContext.ctx,
      'offlinecache',
      offlineModeOverride ? '1' : '0',
      {
        domain: runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
        path: '/',
        expires: dayjs(MAX_COOKIE_DATE).toDate()
      }
    )
  } else {
    offlineModeOverride = Boolean(Number(getCookie(appContext.ctx, 'offlinecache')))
  }

  const isErrorPage = appContext.ctx.res?.statusCode === 404 || appContext.ctx.res?.statusCode === 500
  await redirectFromRestricted(appContext.ctx || undefined)
  const appProps = await NextApp.getInitialProps(appContext)
  const serializedUserData = getCookie(appContext.ctx, COOKIES.USER_DATA)
  const serializedConsultantLogin = getCookie(appContext.ctx, COOKIES.CONSULTANT_LOGIN)
  let userData: User | undefined

  try {
    userData = JSON.parse(serializedUserData)
  } catch (error) {
    userData = undefined
  }

  return {
    isErrorPage,
    userData: {
      ...userData,
      consultantLogin: serializedConsultantLogin,
      isOfflineSyncEnabled: offlineModeOverride && runtimeConfig.publicRuntimeConfig.FEATURE_OFFLINE_SYNC
    },
    ...appProps
  }
}

export default App
