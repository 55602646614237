import React, { VoidFunctionComponent } from 'react'
import { Container, Typography } from '@material-ui/core'

import dayjs from 'helpers/dayjs'
import { StyledWrapper } from './Copyright.styled'

const Copyright: VoidFunctionComponent = () => (
  <StyledWrapper>
    <Container maxWidth='md'>
      <Typography display='block' variant='caption' color='textSecondary'>
          Opublikowane na stronach www.wakacje.pl informacje lub ceny nie stanowią oferty w rozumieniu przepisów kodeksu
          cywilnego.
      </Typography>
      <Typography display='block' variant='caption' color='textSecondary'>
          © 1999-{dayjs().format('YYYY')} Wakacje.pl S.A.
      </Typography>
    </Container>
  </StyledWrapper>
)

export default Copyright
