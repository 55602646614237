import { ChangeEvent, useEffect, useState } from 'react'

const useCheckboxManagement = (
  checked: boolean | undefined,
  defaultChecked: boolean | undefined,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
) => {
  const [checkedCheckbox, setCheckedCheckbox] = useState(checked || defaultChecked || false)

  useEffect(() => {
    setCheckedCheckbox(checked || defaultChecked || false)
  }, [checked, defaultChecked])

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setCheckedCheckbox(checked)
    handleChange(e)
  }

  return {
    checkedCheckbox,
    handleCheckboxChange
  }
}

export default useCheckboxManagement
