import React, { FunctionComponent, ReactNode } from 'react'

import Provider from './initialState'
import useOneLoginStateManager from './useOneLoginStateManager'

interface Props {
  children?: ReactNode
}

const OneLoginProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const value = useOneLoginStateManager()
  return (
    <Provider value={value}>
      {children}
    </Provider>
  )
}

export default OneLoginProvider
