/* eslint-disable no-useless-escape */
export const APP_NAME = 'Panel klienta'

export const PAGE_SIZE = 10

export const ROUTES = {
  MAIN_PAGE: '/order-list',
  LOGOUT: '/logout',
  ADD_ORDER: '/add-order',
  ORDER_DETAILS: '/order',
  SIGN_UP: '/signup',
  ADD_ORDER_CONFIRMED: '/add-order/confirmed',
  ADD_ORDER_ERROR_UNABLE_TO_CONFIRM: '/add-order/error/unable-to-confirm',
  ORDER_DELETE_CONFIRMED: '/order/delete/confirmed',
  ORDER_DELETE_FAILED: '/order/delete/failed',
  ACTION_MAP: {
    // regex list
    DO_NOT_RESTRICT: [
      '^/sw.js',
      '^/confirm-order-merge/email',
      '^/add-order/confirmed',
      '^/auth/user',
      '^/signup',
      '^/login',
      '^/warning',
      '^/thank-you',
      '^/info',
      '^/404',
      '^/500',
      '^/ping',
      '^/set-pk-cookie',
      '^/offline',
      '^/mobile-device-login'
    ],
    ALLOW_WHEN_UNCONFIRMED: ['^/sw.js', '^/form/confirm-agreements', '^/logout', '^/warning'],
    DO_REDIRECT_WHEN_SIGNED_IN: ['^/login', '^/signup'],
    DO_REDIRECT_WHEN_CONFIRMED: ['^/form/confirm-agreements'],
    DO_REDIRECT_WHEN_DOESNT_EXISTS: ['^/form', '^/contact-form', '^/settings']
  },
  REDIRECTS_MAP: {
    WHEN_UNCONFIRMED: '/form/confirm-agreements',
    WHEN_CONFIRMED: '/order-list',
    WHEN_NOT_SIGNED_IN: '/login',
    WHEN_WARNING: '/warning',
    WHEN_PAGE_NOT_FOUND: '/404',
    WHEN_500: '/500',
    WHEN_USER_REMOVED: `${process.env.WAK_URL}/pomoc?removed=true`
  },
  DO_NOT_REDIRECT: '/signup/a'
}

export const WARNINGS = [
  {
    code: '406',
    title: 'Konto zostało wyłączone',
    message: ''
  },
  {
    code: '423',
    title: 'Konto zostało zablokowane',
    message:
      'Ze względów bezpieczeństwa Twoje konto będzie chwilowo niedostępne. ' +
      'Nie musisz nic robić, odblokujemy je za 15 minut.'
  },
  {
    code: '429',
    title: 'Twoje IP zostało zablokowane',
    message:
      'Ze względów bezpieczeństwa Twoje IP zostało chwilowo zablokowane. Nie musisz nic robić, ' +
      'odblokujemy je za 5 minut.'
  },
  {
    code: '451',
    title: 'Konto zostało zablokowane',
    message:
      'Ze względów bezpieczeństwa Twoje konto będzie niedostępne. ' +
      'Jeśli chcesz je odblokować, skontaktuj się z nami, żebyśmy mogli wyjaśnić tę sytuację.'
  },
  {
    code: 'unabletosend',
    title: 'Nie udało się wysłać Twojej wiadomości',
    message:
      'Prosimy, spróbuj jeszcze raz lub skontaktuj się z naszą infolinią pod numerem +48 58 325 29 00, \n' +
      'jeśli sprawa jest pilna.'
  },
  {
    code: 'login',
    title: 'Wystąpił błąd podczas próby logowania.',
    message: 'Prosimy spróbuj ponownie później.'
  }
]

export const THANK_YOU = [
  {
    reason: 'payment',
    title: 'Dziękujemy za wpłatę',
    message: 'Twoja płatność będzie widoczna w ciągu kilku minut.'
  }
]

export const INFO = [
  {
    reason: 'pending-payment',
    title: 'Czekamy na zaksięgowanie wpłaty',
    message: 'Zmiana statusu płatności powinna być widoczna na Twoim koncie w ciągu najbliższych kilku minut.'
  }
]

export const MY_NAVIGATION = {
  PAGES_WITHOUT_NAVIGATION: [
    '^/(form|contact-form)/confirm-agreements',
    '^/login',
    '^/logout',
    '^/signup',
    '^/warning',
    '^/thank-you',
    '^/info',
    '^/404',
    '^/500',
    '^/offline'
  ]
}

export const COOKIES = {
  USER_DATA: 'userData',
  AUTH_EXPIRES: 'authExpires',
  AUTH_DURATION: 'authDuration',
  AUTH_TOKEN: 'authToken',
  CONSULTANT_LOGIN: 'consultantLogin',
  CUSTOMER_ID: 'customerId',
  EM_ID: 'emId',
  PK: 'PK',
  CA_CHAT: '__ca__chat',
  AFFILIATE_CODE: 'affiliateCode',
  AFFILIATE_DATE: 'affiliateDate',
  PRE_CACHE_LAST_UPDATE_TIMESTAMP: 'preCacheLastUpdateTimestamp',
  IS_USER_CONFIRMED_DOCUMENT_DOWNLOAD: 'isUserConfirmedDocumentDownload',
  PRECACHE_REFRESH_COOLDOWN: 'precacheRefreshCooldown',
  REDIRECT_URL: 'redirectURL'
}

export const PK_COOKIE_VALUE = '1D36021FB77392B2789E72B3D4C67EC8F7344D94E8964626A33977332A6555C9'

export const ERROR_NAME = 'Błąd'
export const ERROR_MESSAGE = 'Wystąpił problem.'
export const SETTINGS_ERROR_MESSAGE = 'Nie udało się wysłać Twojego formularza'

export const GOOGLE_ANALYTICS = 'GTM-M5G4V6'
export const MAX_COOKIE_DATE = '2038-01-19' // nie można wprowadzić daty po 2038-01-19 https://en.wikipedia.org/wiki/Year_2038_problem
export const FeedbackContainerZIndex = 2147483640

export const LOCAL_STORAGE_ORDER_CACHE = 'ordersCache'
