import React from 'react'

import { FormControlProps } from '@material-ui/core'
import { StyledFormControl } from './styled'

interface FormControl extends FormControlProps {}

const FormControl = (props: FormControl) => <StyledFormControl {...props} />

export default FormControl
