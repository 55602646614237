import React from 'react'

import Grid from '@material-ui/core/Grid'
import { GridProps } from '@material-ui/core'

interface Col extends GridProps {
  children: React.ReactNode
}

const Col = ({ children, ...props }: Col) => (
  <Grid item {...props}>
    {children}
  </Grid>
)

export default Col
