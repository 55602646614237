import styled from 'styled-components'

export const ConsultantBar = styled.div`
  background-color: ${({ theme }) => theme.colors.orange};
  color: white;
  font-weight: bold;
  text-align: center;
  position: sticky;
  padding: 4px 0;
  top:0;
  z-index: 1501; //nadpisuje największy możliwy z-index, które może mieć komponent z paczki Materail-UI
`
