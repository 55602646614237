import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Travel = (props: Props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M10.5014 9.17758C10.9797 9.45372 11.1436 10.0653 10.8675 10.5436L5.86747 19.2039C5.59133 19.6821 4.97974 19.846 4.50145 19.5699C4.02315 19.2937 3.85928 18.6821 4.13542 18.2039L9.13542 9.5436C9.41156 9.06531 10.0232 8.90144 10.5014 9.17758Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M0.000976562 18.9994C0.000976562 18.4471 0.448692 17.9994 1.00098 17.9994H19.001C19.5533 17.9994 20.001 18.4471 20.001 18.9994C20.001 19.5517 19.5533 19.9994 19.001 19.9994H1.00098C0.448692 19.9994 0.000976562 19.5517 0.000976562 18.9994Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M2.94251 4.27004C5.29784 0.190482 10.5143 -1.20728 14.5939 1.14805L15.409 1.61864C19.4885 3.97398 20.8863 9.19048 18.531 13.27C18.1249 13.9734 17.2255 14.2144 16.5221 13.8083L3.48078 6.2789C2.77741 5.87281 2.53642 4.97341 2.94251 4.27004ZM4.95759 4.82213L17.0453 11.801C18.4912 8.75768 17.3776 5.06463 14.409 3.35069L13.5939 2.88011C10.6253 1.16617 6.87022 2.04832 4.95759 4.82213Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M13.2004 1.16322C13.8129 1.07135 14.4916 1.08901 15.0014 1.3833L14.0014 3.11535C14.0154 3.12347 14.0226 3.12719 14.0223 3.12827C14.0206 3.13309 13.8679 3.08546 13.497 3.14109C13.0948 3.20143 12.5424 3.37037 11.8932 3.72537C10.6015 4.43165 8.95148 5.8622 7.40369 8.54305L5.67164 7.54305C7.37353 4.59528 9.27234 2.879 10.9336 1.97058C11.7609 1.51823 12.536 1.26286 13.2004 1.16322Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M16.0926 2.83306C15.8659 2.25669 15.5111 1.67758 15.0014 1.3833L14.0014 3.11535C13.9873 3.10723 13.9806 3.10294 13.9799 3.1038C13.9766 3.10766 14.0941 3.21606 14.2314 3.56508C14.3803 3.94363 14.5102 4.50645 14.5273 5.2462C14.5615 6.71793 14.1476 8.8622 12.5998 11.543L14.3319 12.543C16.0338 9.59528 16.5707 7.09272 16.5268 5.19978C16.5049 4.25719 16.3385 3.45822 16.0926 2.83306Z' fill='currentColor' />
  </svg>

)

export default Travel
