import React, { ReactElement } from 'react'
import * as Sentry from '@sentry/nextjs'
import { ToastContainer } from 'react-toastify'
import Context from 'context'
import ToastStyles from 'helpers/toast/toastStyles'
import PwaManagement from '../PwaManagement'
import ErrorLayout from '../Layout/ErrorLayout'
import Layout from '../Layout/Layout'
import { User } from '../Header/types'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
  children: ReactElement
  isErrorPage: boolean
  userData: User
}

const AppProvider = ({ children, isErrorPage, userData }: Props) => (
  <Sentry.ErrorBoundary>
    <Context initialUserContext={userData}>
      <ToastStyles />
      <PwaManagement />
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        position='bottom-right'
      />
      {isErrorPage ? (
        <ErrorLayout>
          {children}
        </ErrorLayout>
      ) : (
        <Layout>
          {children}
        </Layout>
      )}
    </Context>
  </Sentry.ErrorBoundary>
)

export default AppProvider
