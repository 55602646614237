import React from 'react'
import Svg from './BaseIcon'

const ChevronRight = (props) => (
  <Svg width='16' height='16' viewBox='0 0 17 16' fill='none' {...props}>
    <path d='M4.5 12.6L6 14l6.5-6L6 2 4.5 3.4l5 4.6-5 4.6z' fill='currentColor' />
  </Svg>
)

export default ChevronRight
