import React, { ChangeEvent } from 'react'
import clsx from 'clsx'

import MUICheckbox from '@material-ui/core/Checkbox'
import { CheckboxProps as MuiCheckboxProps, FormHelperText } from '@material-ui/core'

import FormControl from 'elements/FormControl'
import { useStyles, StyledLabel, RedSpan } from './styled'
import useCheckboxManagement from './useCheckboxManagement'
import useTextVisibility from './useTextVisibility'

export interface CheckboxProps extends MuiCheckboxProps {
  boldedLabel?: boolean
  errorText?: string
  handleChange(e: ChangeEvent<HTMLInputElement>): void
  helpText?: string
  label?: string
  fullText?: string
  required?: boolean
  withoutFormControl?: boolean
}

const Checkbox = ({
  boldedLabel,
  checked,
  defaultChecked,
  errorText,
  handleChange,
  helpText,
  label,
  fullText,
  required,
  withoutFormControl,
  className,
  ...props
}: CheckboxProps) => {
  const classes = useStyles()
  const { checkedCheckbox, handleCheckboxChange } = useCheckboxManagement(checked, defaultChecked, handleChange)
  const { visibleText, isVisibleMoreButton, hideFullText, showFullText } = useTextVisibility(label, fullText)
  const helperText = errorText || helpText

  const checkbox = (
    <MUICheckbox
      className={classes.root}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
      checked={checkedCheckbox}
      color='primary'
      onChange={handleCheckboxChange}
    />
  )

  const labelInner = (
    <>
      {required && <RedSpan>*</RedSpan>}
      <div
        className={boldedLabel ? clsx(classes.labelInner, classes.labelInnerBolded) : classes.labelInner}
        dangerouslySetInnerHTML={{ __html: String(visibleText) }}
      />{' '}
      {fullText && (
        <>
          {isVisibleMoreButton ? <span onClick={showFullText}>Rozwiń</span> : <span onClick={hideFullText}>Zwiń</span>}
        </>
      )}
    </>
  )

  return (
    <>
      {withoutFormControl ? (
        <StyledLabel control={checkbox} label={labelInner} className={className} />
      ) : (
        <FormControl error={Boolean(errorText)} required={required}>
          <StyledLabel control={checkbox} label={labelInner} />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </>
  )
}

export default Checkbox
