import { useCallback, useEffect, useState } from 'react'

import { initialState, ViewState } from './initialState'

const useViewStateManager = () => {
  const [state, setState] = useState(initialState)

  const handleStateChange = useCallback(
    (data: Partial<ViewState>) => {
      setState(prevState => ({
        ...prevState,
        ...data
      }))
    },
    [setState]
  )

  const clearState = useCallback(() => {
    setState({ ...initialState })
  }, [setState])

  useEffect(
    () => {
      // Aplikacje ściągnięte na desktop lub mobile działją w display-mode: standalone, który jest ustawiony w manifest.json
      // i na tej podstawie ustalamy flage.
      const displayModeStandaloneMediaQuery = window.matchMedia('(display-mode: standalone)')

      if (displayModeStandaloneMediaQuery.matches) {
        handleStateChange({
          isAppWorkingOutOfBrowser: true
        })
      }

      const updateDisplayMode = (event: MediaQueryListEvent) => {
        if (event.matches) {
          handleStateChange({
            isAppWorkingOutOfBrowser: true
          })
        } else {
          handleStateChange({
            isAppWorkingOutOfBrowser: false
          })
        }
      }

      // Z przeglądarki można przejść natychmiastowo do ściągniętej aplikacji, więc ten event listener sprawdza czy nastąpiło takie zdarzenie.
      if (displayModeStandaloneMediaQuery.addEventListener) {
        displayModeStandaloneMediaQuery.addEventListener('change', updateDisplayMode)
        return () => {
          displayModeStandaloneMediaQuery.removeEventListener('change', updateDisplayMode)
        }
      } else {
        // Safari <13 support
        displayModeStandaloneMediaQuery.addListener(updateDisplayMode)
        return () => {
          displayModeStandaloneMediaQuery.removeListener(updateDisplayMode)
        }
      }
    },
    []
  )

  useEffect(
    () => {
      const updateStatus = () => {
        if (navigator.onLine) {
          handleStateChange({
            isOnline: true
          })
        } else {
          handleStateChange({
            isOnline: false
          })
        }
      }

      updateStatus()
      window.addEventListener('online', updateStatus)
      window.addEventListener('offline', updateStatus)

      return () => {
        window.removeEventListener('online', updateStatus)
        window.removeEventListener('offline', updateStatus)
      }
    },
    []
  )

  return {
    ...state,
    handleStateChange,
    clearState
  }
}

export default useViewStateManager
