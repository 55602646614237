import { useContext } from 'react'

import { oneLoginContext } from 'context/OneLoginProvider/initialState'
import { viewContext } from 'context/ViewProvider/initialState'

import cleanUpPreCacheData from 'helpers/cleanUpPreCacheData'
import toast from 'helpers/toast'

const useAccountItem = (isDisabledOffline: boolean, link: string, title: string) => {
  const { logout } = useContext(oneLoginContext)
  const { isOnline } = useContext(viewContext)
  const isDisabled = isOnline ? false : isDisabledOffline

  const handleLogout = () => {
    logout()
    cleanUpPreCacheData()
  }

  const showToast = () => {
    if (isDisabled && link === '/logout') {
      toast.warning(
        'Wylogowanie jest możliwe tylko w trybie online',
        'Włącz Wi-Fi lub transmisje danych komórkowych'
      )
    } else if (isDisabled) {
      toast.warning(
        `Widok "${title}" jest aktualizowany tylko w trybie online`,
        'Włącz Wi-Fi lub transmisje danych komórkowych'
      )
    }
  }

  return {
    handleLogout,
    showToast,
    isDisabled
  }
}

export default useAccountItem
