import React from 'react'
import { ContentWrapper, MessageWrapper, Title, Text, StyledCloseIcon, Wrapper } from './styled'
import SeverityIcon from './SeverityIcon'
import { ToastContentProps } from 'react-toastify'

interface Props extends ToastContentProps {
  text: string;
  title: string;
  isLightTheme?: boolean
}

const Toast = ({
  title,
  text,
  closeToast,
  toastProps,
  isLightTheme
}: Props) => (
  <Wrapper
    $severity={toastProps.type}
    $isLightTheme={isLightTheme}
    data-test={`Toast-${toastProps.type}`}
  >
    <ContentWrapper>
      <SeverityIcon severity={toastProps.type} />
      <MessageWrapper>
        <Title size='m' isBold $isLightTheme={isLightTheme}>{title}</Title>
        <Text size='m' $isLightTheme={isLightTheme}>{text}</Text>
      </MessageWrapper>
    </ContentWrapper>
    <StyledCloseIcon onClick={closeToast} $isLightTheme={isLightTheme} />
  </Wrapper>
)

export default Toast
