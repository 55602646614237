import React from 'react'
import Svg from './BaseIcon'

/* eslint-disable max-len */
const ArrowRight = (props) => (
  <Svg width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M8 .9L6.7 2l5 5H1V9h10.8l-5 5L8 15 15.1 8 8 .9z' fill='currentColor' />
  </Svg>
)

export default ArrowRight
