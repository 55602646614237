import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import updateLocale from 'dayjs/plugin/updateLocale'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(advancedFormat)
dayjs.extend(updateLocale)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('pl')

export const dateFormatExact = 'DD.MM.YYYY hh:mm:ss'
export const dateFormat = 'DD.MM.YYYY'
export const dayFormat = 'DD.MM'

export default dayjs
