import React, { ReactNode } from 'react'

import OneLoginProvider from './OneLoginProvider'
import UserProvider, { UserProviderProps } from './UserProvider'
import ViewProvider from './ViewProvider'
import RemoveUserProvider from './RemoveUserProvider'

interface Props extends UserProviderProps {
  children: ReactNode
}

const Context: React.FC<Props> = ({ children, initialUserContext }: Props) => (
  <UserProvider initialUserContext={initialUserContext}>
    <OneLoginProvider>
      <RemoveUserProvider>
        <ViewProvider>{children}</ViewProvider>
      </RemoveUserProvider>
    </OneLoginProvider>
  </UserProvider>
)

export default Context
