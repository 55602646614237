/* eslint-disable func-call-spacing */
import { Dispatch, createContext } from 'react'
import { InitialState, initialState } from './initialState'
import { RemoveUserActions } from './actions'

export interface RemoveUserContextValue extends InitialState {
  dispatch: Dispatch<RemoveUserActions>
  handleRemoveUser?: () => Promise<void>
}

export const RemoveUserContext = createContext<RemoveUserContextValue>({
  ...initialState,
  dispatch: () => null,
  handleRemoveUser: async () => {}
})
