const customShadows = {
  shadows1: '0px 0px 2px rgba(0, 0, 0, 0.6)',
  shadows2: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.4)',
  shadows3: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.4)',
  shadows4: '0px 0px 12px rgba(0, 0, 0, 0.1)',
  shadows5: '0px 4px 18px rgba(0, 0, 0, 0.1)',
  shadows6: '0px 10px 12px rgba(0, 0, 0, 0.15)'
}

export default customShadows
