import styled from 'styled-components'

import Text from 'elements/Text'
import Image from 'elements/Image'

const StyledText = styled(Text)`
  font-size: 11px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 16px;
`

const StyledImage = styled(Image)<{$withBorder: boolean}>`
  max-width: 100%;
  max-height: 100%;
  margin-right: 16px;
  vertical-align: middle;
  object-fit: contain;
  object-position: center;
  
  ${({ $withBorder }) => $withBorder && `
    height: 36px;
    width: 66px;
    padding: 8px;
    margin-right: 8px;
    border: 1px solid #ccc;
    border-radius: 2px;
    vertical-align: baseline;
  `}
`

export {
  StyledText,
  StyledImage
}
