import { useRouter } from 'next/router'
import { useMediaQuery } from '@material-ui/core'

import { matchByList } from 'helpers/regexp'
import { MY_NAVIGATION } from 'constants/index'

const useLayoutManager = () => {
  const router = useRouter()
  const { pathname } = router
  const isDesktopView = useMediaQuery('(min-width:960px)')
  const isAddOrderView = pathname.includes('/add-order')
  const isOffline = pathname === '/offline'
  const showMyAccountNavigation =
    isDesktopView &&
    !matchByList(MY_NAVIGATION.PAGES_WITHOUT_NAVIGATION, pathname)
  const isLoginView = pathname === '/login' || pathname === '/signup' || pathname === '/mobile-device-login'

  return {
    isLoginView,
    isAddOrderView,
    isOffline,
    showMyAccountNavigation
  }
}

export default useLayoutManager
