import { createStyles, InputBase, Theme, withStyles } from '@material-ui/core'

export const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "'Poppins', 'Lato', sans-serif",
      letterSpacing: '0.2px',
      borderRadius: '2px',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #cccc',
      width: '100%',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      padding: theme.spacing(0, 1.5),
      height: theme.spacing(5),
      '&::-webkit-input-placeholder': {
        fontFamily: "'Poppins', 'Lato', sans-serif",
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: '#999'
      },
      'label[data-shrink=false] + & .MuiInputBase-input::-webkit-input-placeholder': {
        opacity: '1 !important',
        display: 'block',
        color: '#999'
      },
      '&.Mui-error': {
        borderColor: theme.palette.error.main
      }
    },
    focused: {
      borderColor: theme.reBrandingColors.blue800,
      outline: 'none',
      borderRadius: '2px',
      border: '1px solid #51A5DA',
      boxShadow: '0px 0px 4px #51A5DA'
    },
    disabled: {
      backgroundColor: '#f2f2f2'
    },
    input: {
      padding: theme.spacing(1, 0),
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.palette.text.secondary,
      '&[type="date"]': {
        '&::-webkit-calendar-picker-indicator': {
          backgroundImage: 'url("/icons/calendar.svg")',
          outline: 'none'
        }
      }
    }
  })
)(InputBase)
