import styled from 'styled-components'

import { Button, CircularProgress } from '@material-ui/core'

import ChevronRight from 'elements/Icons/ChevronRight'
import ChevronLeft from 'elements/Icons/ChevronLeft'

interface Props {
  size: 'small' | 'large'
  $themeVariant: 'reservation' | 'primary' | 'secondary' | 'text'
}

export const StyledButton = styled(Button)<Props>`
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  ${({ size }) => size === 'small' && 'padding: 4px 32px; min-height: 32px;'}
  ${({ size }) => size === 'large' && 'padding: 8px 32px; min-height: 40px;'}
  ${({ theme, $themeVariant }) =>
    $themeVariant === 'reservation' &&
    `
    background-color: ${theme.reBrandingColors.yellow600};
    color: ${theme.reBrandingColors.black};
    box-shadow: none; 
    &:hover {
      box-shadow: none; 
      background-color: ${theme.reBrandingColors.yellow400};
    }
    &:active {
      background: ${theme.reBrandingColors.yellow600};
      box-shadow: none;
    }
    &:disabled {
      border: none;
      color: ${theme.reBrandingColors.gray400};
      background-color: ${theme.reBrandingColors.gray100};
    }
  `}
  ${({ theme, $themeVariant }) =>
    $themeVariant === 'primary' &&
    `
    text-transform: initial;
    background-color: ${theme.reBrandingColors.blue800};
    border: 1px solid ${theme.reBrandingColors.blue800}; 
    color: ${theme.reBrandingColors.white};
    box-shadow: none; 
    &:hover {
      background-color: ${theme.reBrandingColors.blue600};
      border: 1px solid ${theme.reBrandingColors.blue600}; 
      box-shadow: none;
    }
    &:active {
      box-shadow: 0px 0px 4px ${theme.reBrandingColors.blue600};
    }
    &:disabled {
      box-shadow: none; 
      border: none;
      color: ${theme.reBrandingColors.gray400};
      background-color: ${theme.reBrandingColors.gray100};
    }
  `}
  ${({ theme, $themeVariant }) =>
    $themeVariant === 'secondary' &&
    `
    text-transform: initial;
    background-color: ${theme.reBrandingColors.white};
    border: 1px solid ${theme.reBrandingColors.blue800}; 
    color: ${theme.reBrandingColors.blue800};
    box-shadow: none; 
    &:hover {
      background-color: ${theme.reBrandingColors.blue100};
      border: 1px solid ${theme.reBrandingColors.blue800}; 
      box-shadow: none; 
    }
    &:active {
      box-shadow: 0px 0px 4px ${theme.reBrandingColors.blue600};
    }
    &:disabled {
      color: ${theme.reBrandingColors.gray400};
      background-color: ${theme.reBrandingColors.white};
      border: 1px solid ${theme.reBrandingColors.gray400}; 
    }
  `}
  ${({ theme, $themeVariant }) =>
          $themeVariant === 'text' &&
          `
    text-transform: initial;
    font-weight: normal;
    background-color: transparent;
    border: 2px solid transparent;
    color: ${theme.reBrandingColors.blue800};
    box-shadow: none; 
    padding: 8px;
    
    &:hover {
    transition: background-color 0.5s ease;
      background: linear-gradient(
        0deg, 
        ${theme.reBrandingColors.blue800}0D, 
        ${theme.reBrandingColors.blue800}0D
      ), 
      #FFFFFF;
      border: 2px solid transparent; 
      box-shadow: none; 
    }
    &:active {
      box-shadow: 0px 0px 4px ${theme.reBrandingColors.blue600};
    }
    &:disabled {
      color: ${theme.reBrandingColors.gray400};
      background-color: ${theme.reBrandingColors.white};
      border: 1px solid ${theme.reBrandingColors.white}; 
    }
    & .MuiCircularProgress-root{
      position: initial;
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }
  `}
  & .MuiFormControlLabel-root{
    margin: 0 4px 0 0;
  }
`

export const StyledWrapper = styled.div<{ $isVisible: boolean }>`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  ${({ $isVisible }) => `
    visibility: ${$isVisible ? 'visible' : 'hidden'};
  `}
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
`

export const StyledChevronRightIcon = styled(ChevronRight)`
  margin-left: 8.47px;
`

export const StyledChevronLeftIcon = styled(ChevronLeft)`
  margin-right: 8.47px;
`
