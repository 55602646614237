import { getCookie } from 'helpers/cookies'
import { COOKIES } from 'constants/index'

const CUSTOMER_CONFIRM = '/cub/api/customers/confirm'

const extendRequestBody = <Body extends object>(body: Body, url: string) => {
  if (body && url.includes(CUSTOMER_CONFIRM)) {
    const cookieCaChat = getCookie(null, COOKIES.CA_CHAT) || null

    return {
      ...body,
      cookieCaChat
    }
  }

  return body
}

export default extendRequestBody
