import LocalStorageCache from '../types/LocalStorageCache'
import PreCache from '../types/PreCache'
import { OrderListItemBasic } from 'interfaces'

const parseCacheResponse = (cacheResponse: PreCache) => {
  const localStorageCache: LocalStorageCache[] = []

  localStorageCache.push({
    url: '/orders/listing',
    response: {
      totalRows: cacheResponse.totalRows,
      hasOrders: cacheResponse.hasOrders,
      availableFilters: cacheResponse.availableFilters,
      orders: cacheResponse.orders.map<OrderListItemBasic>((order) => ({
        orderId: order.orderId,
        brandCode: order.brandCode,
        startsAt: order.startsAt,
        endsAt: order.endsAt,
        productName: order.productName,
        statusName: order.statusName,
        mainOrderItemId: order.mainOrderItemId,
        mainOrderItemType: order.mainOrderItemType,
        crossSells: order.crossSells,
        isAddOpinionAvailable: order.isAddOpinionAvailable,
        isOpinionSent: order.isOpinionSent,
        addOpinionUrl: order.addOpinionUrl
      }))
    }
  })

  localStorageCache.push({
    url: '/orders/listing/products',
    response: cacheResponse.listingProducts
  })

  for (const order of cacheResponse.orders) {
    const { orderId, header, extendDetails, additionalProducts } = order

    localStorageCache.push({
      url: `/orders/${orderId}/header`,
      response: header
    })
    localStorageCache.push({
      url: `/orders/${orderId}/extended-details`,
      response: extendDetails
    })
    localStorageCache.push({
      url: `/orders/${orderId}/additional-products`,
      response: additionalProducts
    })
  }

  return localStorageCache
}

export default parseCacheResponse
