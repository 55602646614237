import { useContext } from 'react'

import { OnClickLink } from '@wakds/user-account-widget'

import { oneLoginContext } from 'context/OneLoginProvider/initialState'
import { viewContext } from 'context/ViewProvider/initialState'

import cleanUpPreCacheData from 'helpers/cleanUpPreCacheData'
import toast from 'helpers/toast'

const useUserAccountManager = () => {
  const { logout, logoutAfterRemove } = useContext(oneLoginContext)
  const { isOnline } = useContext(viewContext)

  const handleClickElement = () => {
    logout()
    cleanUpPreCacheData()
  }

  const handleLogoutAfterRemove = () => {
    logoutAfterRemove()
  }

  const showToast: OnClickLink = ({ label }) => {
    if (label === 'Wyloguj' && !isOnline) {
      toast.warning('Wylogowanie jest możliwe tylko w trybie online', 'Włącz Wi-Fi lub transmisje danych komórkowych')
    } else if (label === 'Zarządzaj 1login' && !isOnline) {
      toast.warning(
        'Zarządzenie 1login jest możliwe tylko w trybie online',
        'Włącz Wi-Fi lub transmisje danych komórkowych'
      )
    } else if (label && !isOnline) {
      toast.warning(
        `Widok "${label}" jest aktualizowany tylko w trybie online`,
        'Włącz Wi-Fi lub transmisje danych komórkowych'
      )
    }
  }

  return {
    isOnline,
    handleClickElement,
    showToast,
    handleLogoutAfterRemove
  }
}

export default useUserAccountManager
