import { request } from 'helpers/request'
import { getCookie } from 'helpers/cookies'
import { COOKIES, LOCAL_STORAGE_ORDER_CACHE, MAX_COOKIE_DATE } from 'constants/index'
import { setCookie } from 'nookies'
import dayjs from 'helpers/dayjs'
import toast from 'helpers/toast'
import setItemInLocalStorage from 'helpers/setItemInLocalStorage'
import PreCache from '../types/PreCache'
import parseCacheResponse from './parseCacheResponse'
import createRefreshPreCacheCookie from './createRefreshPreCacheCookie'
import runtimeConfig from 'common/config'

const preCacheOrderData = async () => {
  const synchronizeToast = toast.info('Synchronizowanie danych', '', { autoClose: false, isLightTheme: true })
  try {
    const { ordersLastChangeAt } = await request<{ ordersLastChangeAt: number|null }>('/orders/pwa-check')
    const lastSavedUpdateTimestamp = getCookie(null, COOKIES.PRE_CACHE_LAST_UPDATE_TIMESTAMP)

    if (ordersLastChangeAt === null || ordersLastChangeAt === Number(lastSavedUpdateTimestamp)) {
      return Promise.resolve()
    }

    setCookie(
      null,
      COOKIES.PRE_CACHE_LAST_UPDATE_TIMESTAMP,
      ordersLastChangeAt.toString(),
      {
        domain: runtimeConfig.publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
        path: '/',
        expires: dayjs(MAX_COOKIE_DATE).toDate()
      }
    )

    const cacheResponse = await request<PreCache>('/orders/full-extend/listing')
    const localStorageCache = parseCacheResponse(cacheResponse)

    setItemInLocalStorage(LOCAL_STORAGE_ORDER_CACHE, localStorageCache)
    toast.dismiss(synchronizeToast)

    return Promise.resolve()
  } catch (error) {
    toast.dismiss(synchronizeToast)
    toast.error('Nie udało się zsynchronizować danych', '', { isLightTheme: true })

    return Promise.reject(error)
  } finally {
    createRefreshPreCacheCookie()
    toast.dismiss(synchronizeToast)
  }
}

export default preCacheOrderData
