import React from 'react'

import { TypographyProps } from '@material-ui/core'

import { HeaderVariant } from './HeaderVariant'
import { StyledTypography } from './styles'

interface Props extends TypographyProps {
  headerVariant: HeaderVariant
}

const Header: React.FC<Props> = ({ headerVariant, ...props }: Props) => (
  <StyledTypography
    $headerVariant={headerVariant}
    {...props}
  />
)

export default Header
