import { useEffect } from 'react'

import { Workbox } from 'workbox-window'

declare global {
  interface Window {
    workbox: Workbox
  }
}

const useServiceWorkerManager = () => {
  useEffect(
    () => {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.caches) {
        window.workbox = new Workbox('/sw.js', { scope: '/' })
        window.workbox.register()
      }
    },
    []
  )
}

export default useServiceWorkerManager
