import React from 'react'

import Contact from 'elements/Icons/Contact'
import Logout from 'elements/Icons/Logout'
import Travel from 'elements/Icons/Travel'
import Settings from 'elements/Icons/Settings'
import Voucher from 'elements/Icons/Menu/Voucher'

import runtimeConfig from 'common/config'

const { publicRuntimeConfig } = runtimeConfig

const menuItems = [
  {
    icon: <Travel />,
    title: 'Podróże',
    link: '/order-list',
    activeOnPages: ['/order-list', '/add-order', '/order'],
    isDisabled: false,
    isDisabledOffline: false
  },
  {
    icon: <Contact />,
    title: 'Kontakt',
    link: '/contact',
    activeOnPages: ['/contact'],
    isDisabled: false,
    isDisabledOffline: true
  },
  {
    icon: <Voucher />,
    title: 'Sprawdź saldo bonu',
    link: '/vouchers/check-balance',
    activeOnPages: ['/vouchers/check-balance'],
    isDisabled: !(publicRuntimeConfig.CHECK_VOUCHER_BALANCE_ENABLED),
    isDisabledOffline: true
  },
  {
    icon: <Settings />,
    title: 'Ustawienia',
    link: '/settings',
    activeOnPages: ['/settings'],
    isDisabled: false,
    isDisabledOffline: true
  },
  {
    icon: <Logout />,
    title: 'Wyloguj',
    link: '/logout',
    isDisabled: false,
    isDisabledOffline: true
  }
]

export default menuItems
