import React, { ReactNode } from 'react'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Newsletter from 'components/Newsletter'
import Partners from 'components/Footer/Partners'
import Lists from 'components/Footer/Lists'

import CssBaseline from 'elements/CssBaseline'
import { Content, Wrapper } from './styled'

interface ErrorLayout {
  children: NonNullable<ReactNode>
}

const ErrorLayout = ({ children }: ErrorLayout) => (
  <Wrapper>
    <CssBaseline />
    <Header />
    <Content maxWidth='lg'>{children}</Content>
    <Newsletter />
    <Lists />
    <Partners />
    <Footer />
  </Wrapper>
)

export default ErrorLayout
