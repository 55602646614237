import { useContext } from 'react'

import { viewContext } from 'context/ViewProvider/initialState'

import toast from 'helpers/toast'

const useOfflineWidgetManagement = () => {
  const { isOnline } = useContext(viewContext)

  const handleClickOfflineWidget = () => {
    if (!isOnline) {
      toast.warning(
        'Zakładka "Ulubione" jest dostępna tylko w trybie online',
        'Włącz Wi-Fi lub transmisje danych komórkowych'
      )
    }
  }

  return {
    handleClickOfflineWidget,
    isOnline
  }
}

export default useOfflineWidgetManagement
