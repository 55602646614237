import usePreCacheManager from './usePreCacheManager'
import useServiceWorkerManager from './useServiceWrokerManager'

const PwaManagement = () => {
  usePreCacheManager()
  useServiceWorkerManager()

  return null
}

export default PwaManagement
