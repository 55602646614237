import { setCookie } from 'nookies'

import dayjs from 'helpers/dayjs'
import { getCookie } from 'helpers/cookies'
import { COOKIES } from 'constants/index'
import { User } from './types'
import runtimeConfig from 'common/config'
import { hashEmailWith265Sha } from 'helpers/hashEmailWith265Sha'

const { publicRuntimeConfig } = runtimeConfig

function saveUserDataInCookie(userData: Omit<User, 'isOfflineSyncEnabled'>) {
  const authExpirationDate = getCookie(null, COOKIES.AUTH_EXPIRES)

  if (!authExpirationDate) {
    return
  }

  const expirationDateInSeconds = Number(authExpirationDate)
  const userDataCookieExpirationDate = dayjs.unix(expirationDateInSeconds).toDate()
  setCookie(null, COOKIES.USER_DATA, JSON.stringify(userData), {
    domain: publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
    path: '/',
    expires: userDataCookieExpirationDate
  })
  setCookie(null, COOKIES.EM_ID, hashEmailWith265Sha(userData.email), {
    domain: publicRuntimeConfig.REACT_APP_COOKIE_DOMAIN,
    path: '/',
    expires: userDataCookieExpirationDate
  })
}

export default saveUserDataInCookie
