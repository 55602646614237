import styled from 'styled-components'

import Container from 'elements/Container/Container'
import Col from 'elements/Col/Col'
import Link from 'elements/Link'

import Paragraph from 'styleguide/typography/Paragraph'

export const Wrapper = styled(Container)`
  max-width: 1140px;
  ${({ theme }) => `
    padding: ${theme.spacing(7, 0, 0)};
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(5, 2, 0)};
    }
  `}
`

export const StyledCol = styled(Col)`
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      margin-bottom: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(1)}px;
    }
  `}
`

export const StyledParagraph = styled(Paragraph)`
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(2)}px;
  `}
`

export const AddressWrapper = styled.div`
  width: 100%;
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)}px;
  `}
`

export const ContactParagraph = styled(Paragraph)`
  padding: 0;
  margin: 0;
`

export const ListItem = styled(Link)`
  display: block;
  width: 100%;
  ${({ theme }) => `
    margin: ${theme.spacing(1, 0)};
    color: ${theme.palette.text.secondary};
    font-size: 14px;
    line-height: 20px;
    &:hover{
      color: ${theme.palette.primary.dark};
    }
  `}
`

export const BorderedIcon = styled(Link)`
  color: #999;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
  transition: 0.3s;
  ${({ theme }) => `
    width: ${theme.spacing(4)}px;
    height: ${theme.spacing(4)}px;
    margin: ${theme.spacing(1, 2, 0, 0)};
    &:hover{
      color: #ccc;
      border: 1px solid #ccc;
    }
    svg{
      width: ${theme.spacing(2)}px;
      height: ${theme.spacing(2)}px;
      object-fit: contain;
    }
  `}
`
