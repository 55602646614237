import { createContext } from 'react'

import { OneLoginContext } from './OneLoginContext.interface'
import { ROUTES } from '../../constants'

export const initialState = {
  logout: () => {
    window.location.assign(ROUTES.LOGOUT)
  },
  logoutAfterRemove: () => {
    window.location.assign(`${ROUTES.LOGOUT}?removed=true`)
  }
}

export type OneLoginState = Partial<typeof initialState>

export const oneLoginContext = createContext<OneLoginContext>({
  ...initialState,
  // działanie funkcji jest w ./useOneLoginStateManager.ts
  handleStateChange: () => {},
  clearState: () => {}
})

export default oneLoginContext.Provider
