const lists = [
  {
    title: 'Wakacje.pl',
    elements: [
      {
        name: 'O portalu',
        link: 'https://www.wakacje.pl/oportalu.html'
      },
      {
        name: 'O firmie',
        link: 'https://www.wakacje.pl/oportalu.html'
      },
      {
        name: 'Polityka prywatności',
        link: 'https://www.wakacje.pl/oportalu.html'
      },
      {
        name: 'Pliki cookie',
        link: 'https://www.wakacje.pl/oportalu.html'
      },
      {
        name: 'Mapa serwisu',
        link: 'https://www.wakacje.pl/oportalu.html'
      },
      {
        name: 'Kontakt',
        link: 'https://www.wakacje.pl/oportalu.html'
      }
    ]
  },
  {
    title: 'Usługi i współpraca',
    elements: [
      {
        name: 'Partnerzy',
        link: 'https://www.wakacje.pl/partnerzy.html'
      },
      {
        name: 'Franczyza',
        link: 'https://www.wakacje.pl/partnerzy.html'
      },
      {
        name: 'Kariera',
        link: 'https://www.wakacje.pl/partnerzy.html'
      },
      {
        name: 'Centrum prasowe',
        link: 'https://www.wakacje.pl/partnerzy.html'
      }
    ]
  },
  {
    title: 'Dla klientów',
    elements: [
      {
        name: 'Jak rezerwować',
        link: 'https://www.wakacje.pl/partnerzy.html'
      },
      {
        name: 'Hotele',
        link: 'https://www.wakacje.pl/partnerzy.html'
      },
      {
        name: 'Rankingi',
        link: 'https://www.wakacje.pl/partnerzy.html'
      },
      {
        name: 'Forum',
        link: 'https://www.wakacje.pl/partnerzy.html'
      }
    ]
  }
]

export default lists
