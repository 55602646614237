import { NextPageContext } from 'next'
import { getCookie } from 'helpers/cookies'
import setRedirectURLCookie from 'helpers/setRedirectURLCookie'
import { ROUTES } from 'constants/index'
import { matchByList } from './regexp'

const redirect = (ctx: NextPageContext, url: string) => {
  if (ctx?.res && !ctx.res.finished) {
    ctx.res.writeHead(307, { Location: url })
    ctx.res.end()
  }
}

export const redirectFromRestricted = (ctx: NextPageContext) => {
  if (!ctx) {
    return
  }

  const authToken = getCookie(ctx, 'authToken')
  const redirectURL = getCookie(ctx, 'redirectURL')

  const currentUrl = ctx.req?.originalUrl || ''

  // user niezalogowany - wiemy to z gory bo nie ma ciacha
  if (
    (undefined === authToken || authToken === '') &&
    !matchByList(ROUTES.ACTION_MAP.DO_NOT_RESTRICT, currentUrl)
  ) {
    if (currentUrl !== ROUTES.REDIRECTS_MAP.WHEN_NOT_SIGNED_IN) {
      setRedirectURLCookie(ctx)
    }
    redirect(ctx, ROUTES.REDIRECTS_MAP.WHEN_NOT_SIGNED_IN)
    return
  }

  // user zalogowany nie musi ogladac stron logowania
  // nie powinień przekierowywać strony "/signup/a" z danymi afiliacyjnymi
  if (
    authToken &&
    matchByList(ROUTES.ACTION_MAP.DO_REDIRECT_WHEN_SIGNED_IN, currentUrl) &&
    !currentUrl.includes(ROUTES.DO_NOT_REDIRECT)
  ) {
    redirect(ctx, (redirectURL || ROUTES.REDIRECTS_MAP.WHEN_CONFIRMED))
  }
}
