const elevations = {
  black: {
    shadows01: '0px 0px 2px rgba(0, 86, 189, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.15)',
    shadows02: '0px 1px 4px rgba(0, 86, 189, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.15)',
    shadows03: '0px 1px 8px rgba(0, 86, 189, 0.18), 0px 5px 12px rgba(0, 0, 0, 0.12)',
    shadows04: '0px 2px 10px rgba(0, 86, 189, 0.12), 0px 7px 20px rgba(0, 0, 0, 0.16)',
    shadows05: '0px 4px 10px rgba(0, 86, 189, 0.12), 0px 10px 24px rgba(0, 0, 0, 0.16)',
    shadows06: '0px 8px 14px rgba(0, 86, 189, 0.1), 0px 10px 32px rgba(0, 0, 0, 0.16)'
  },
  blue: {
    light: {
      shadows01: '0px 0px 2px rgba(0, 86, 189, 0.1), 0px 1px 4px rgba(0, 86, 189, 0.2)',
      shadows02: '0px 1px 4px rgba(0, 86, 189, 0.1), 0px 2px 6px rgba(0, 86, 189, 0.15)',
      shadows03: '0px 1px 8px rgba(0, 86, 189, 0.18), 0px 5px 12px rgba(0, 86, 189, 0.12)',
      shadows04: '0px 2px 10px rgba(0, 86, 189, 0.12), 0px 7px 20px rgba(0, 86, 189, 0.16)',
      shadows05: '0px 4px 10px rgba(0, 86, 189, 0.12), 0px 10px 24px rgba(0, 86, 189, 0.16)',
      shadows06: '0px 8px 14px rgba(0, 86, 189, 0.1), 0px 10px 32px rgba(0, 86, 189, 0.16)'
    },
    dark: {
      shadows01: '0px 0px 2px rgba(0, 86, 189, 0.25), 0px 1px 4px rgba(0, 86, 189, 0.5)',
      shadows02: '0px 1px 4px rgba(0, 86, 189, 0.27), 0px 2px 6px rgba(0, 86, 189, 0.45)',
      shadows03: '0px 1px 8px rgba(0, 86, 189, 0.35), 0px 5px 12px rgba(0, 86, 189, 0.35)',
      shadows04: '0px 2px 10px rgba(0, 86, 189, 0.32), 0px 7px 20px rgba(0, 86, 189, 0.4)',
      shadows05: '0px 4px 10px rgba(0, 86, 189, 0.3), 0px 10px 24px rgba(0, 86, 189, 0.4)',
      shadows06: '0px 8px 14px rgba(0, 86, 189, 0.24), 0px 10px 32px rgba(0, 86, 189, 0.5)'
    }
  }
}

export default elevations
