import React from 'react'

import { ButtonProps } from '@material-ui/core'

import Checkbox from 'elements/Checkbox'
import useCheckboxChange from './useCheckboxChange'

import { StyledWrapper, StyledButton, StyledLoader, StyledChevronRightIcon, StyledChevronLeftIcon } from './styled'
import Link from 'next/link'

interface Props extends ButtonProps {
  size: 'small' | 'large'
  themeVariant: 'reservation' | 'primary' | 'secondary' | 'text'
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  shouldRenderLeftArrow?: boolean
  shouldRenderRightArrow?: boolean
  shouldRenderCheckbox?: boolean
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      size,
      themeVariant,
      variant,
      shouldRenderLeftArrow = false,
      shouldRenderRightArrow = false,
      shouldRenderCheckbox = false,
      isLoading = false,
      href,
      onCheckboxChange,
      ...props
    }: Props,
    _ref
  ) => {
    const {
      isChecked,
      handleCheckboxChange,
      handleButtonClick
    } = useCheckboxChange(onCheckboxChange)

    return (href ? (
      <Link href={href}>
        <StyledButton
          size={size}
          variant={variant}
          $themeVariant={themeVariant}
          ref={_ref}
          onClick={handleButtonClick}
          {...props}
        >
          {isLoading && <StyledLoader size={24} color='inherit' />}
          <StyledWrapper $isVisible={!isLoading || themeVariant === 'text'}>
            {shouldRenderLeftArrow && <StyledChevronLeftIcon />}
            {shouldRenderCheckbox &&
              <Checkbox
                label=''
                withoutFormControl
                checked={isChecked}
                handleChange={handleCheckboxChange}
              />}
            {children}
            {shouldRenderRightArrow && <StyledChevronRightIcon />}
          </StyledWrapper>
        </StyledButton>
      </Link>
    ) : (
      <StyledButton
        size={size}
        variant={variant}
        $themeVariant={themeVariant}
        onClick={handleButtonClick}
        ref={_ref}
        {...props}
      >
        {isLoading &&
          <StyledLoader
            size={24}
            color='inherit'
          />}
        <StyledWrapper
          $isVisible={
            !isLoading ||
            themeVariant === 'text'
          }
        >
          {shouldRenderLeftArrow && <StyledChevronLeftIcon />}
          {shouldRenderCheckbox &&
            <Checkbox
              label=''
              withoutFormControl
              checked={isChecked}
              handleChange={handleCheckboxChange}
            />}
          {children}
          {shouldRenderRightArrow && <StyledChevronRightIcon />}
        </StyledWrapper>
      </StyledButton>
    )
    )
  }
)

export default Button
