import React, { ReactNode, useContext } from 'react'

import { CssBaseline } from '@material-ui/core'

import Header from 'components/Header'
import Footer from 'components/Footer'
import MyAccountNavigation from 'components/MyAccountNavigation'

import Row from 'elements/Row'
import { Wrapper, Content, LeftColumn, Heading, RightColumn } from './styled'
import useLayoutManager from './useLayoutManager'
import RemoveUserLayout from './RemoveUserLayout/RemoveUserLayout'
import { RemoveUserContext } from 'context/RemoveUserProvider/Context'
import { BaseStyles } from './BaseStyles'

interface Layout {
  children: ReactNode
}

const Layout = ({ children }: Layout) => {
  const { isSubmitting, isRemoveError } = useContext(RemoveUserContext)
  const { isLoginView, isAddOrderView, isOffline, showMyAccountNavigation } = useLayoutManager()

  return (
    <Wrapper>
      <CssBaseline />
      <BaseStyles />
      <Header />
      {isLoginView ? (
        <>{children}</>
      ) : (
        <Content
          maxWidth='lg'
          $isAddOrderView={isAddOrderView}
          $isOfflineContent={isOffline || isSubmitting || isRemoveError}
          $isOnTop={isSubmitting || isRemoveError}
        >
          <RemoveUserLayout>
            <Row>
              {showMyAccountNavigation && (
                <LeftColumn>
                  <Heading variant='h1' color='textPrimary'>
                    Moje konto
                  </Heading>
                  <MyAccountNavigation />
                </LeftColumn>
              )}
              <RightColumn $showMyAccountNavigation={showMyAccountNavigation}>{children}</RightColumn>
            </Row>
          </RemoveUserLayout>
        </Content>
      )}
      <Footer />
    </Wrapper>
  )
}

export default Layout
