import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Settings = (props: Props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9ZM7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M7 2C7 0.89543 7.89543 0 9 0H11C12.1046 0 13 0.89543 13 2V3.5H11V2H9V3.5C9 3.85765 8.91404 4.223 8.69399 4.53732C8.47034 4.85676 8.15703 5.05266 7.83295 5.14182C7.22594 5.30882 6.59875 5.11026 6.17668 4.75669L6.1429 4.72839L5.05108 3.63657L3.63687 5.05078L4.73268 6.14659L4.76416 6.18505C5.23914 6.76517 5.1932 7.482 4.98327 7.96785C4.78112 8.43569 4.2818 9 3.5 9H2V11H3.5V13H2C0.89543 13 0 12.1046 0 11V9C0 7.89543 0.89543 7 2 7H2.75766L2.22266 6.46499C1.44161 5.68395 1.44161 4.41762 2.22266 3.63657L3.63687 2.22235C4.41792 1.44131 5.68425 1.44131 6.4653 2.22235L7 2.75706V2Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M7 18C7 19.1046 7.89543 20 9 20H11C12.1046 20 13 19.1046 13 18V16.5H11V18H9V16.5C9 16.1424 8.91404 15.777 8.69399 15.4627C8.47034 15.1432 8.15703 14.9473 7.83295 14.8582C7.22594 14.6912 6.59875 14.8897 6.17668 15.2433L6.1429 15.2716L5.05108 16.3634L3.63687 14.9492L4.73268 13.8534L4.76416 13.815C5.23914 13.2348 5.1932 12.518 4.98327 12.0321C4.78112 11.5643 4.2818 11 3.5 11H2V9H3.5L3.5 7H2C0.89543 7 0 7.89543 0 9V11C0 12.1046 0.89543 13 2 13H2.75766L2.22266 13.535C1.44161 14.3161 1.44161 15.5824 2.22266 16.3634L3.63687 17.7776C4.41792 18.5587 5.68425 18.5587 6.4653 17.7776L7 17.2429V18Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M13 2C13 0.89543 12.1046 0 11 0H9C7.89543 0 7 0.89543 7 2V3.5H9V2H11V3.5C11 3.85765 11.086 4.223 11.306 4.53732C11.5297 4.85676 11.843 5.05266 12.167 5.14182C12.7741 5.30882 13.4013 5.11026 13.8233 4.75669L13.8571 4.72839L14.9489 3.63657L16.3631 5.05078L15.2673 6.14659L15.2358 6.18505C14.7609 6.76517 14.8068 7.482 15.0167 7.96785C15.2189 8.43569 15.7182 9 16.5 9H18V11H16.5V13H18C19.1046 13 20 12.1046 20 11V9C20 7.89543 19.1046 7 18 7H17.2423L17.7773 6.46499C18.5584 5.68395 18.5584 4.41762 17.7773 3.63657L16.3631 2.22235C15.5821 1.44131 14.3158 1.44131 13.5347 2.22235L13 2.75706V2Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M13 18C13 19.1046 12.1046 20 11 20H9C7.89543 20 7 19.1046 7 18V16.5H9V18H11V16.5C11 16.1424 11.086 15.777 11.306 15.4627C11.5297 15.1432 11.843 14.9473 12.167 14.8582C12.7741 14.6912 13.4013 14.8897 13.8233 15.2433L13.8571 15.2716L14.9489 16.3634L16.3631 14.9492L15.2673 13.8534L15.2358 13.815C14.7609 13.2348 14.8068 12.518 15.0167 12.0321C15.2189 11.5643 15.7182 11 16.5 11H18V9H16.5V7H18C19.1046 7 20 7.89543 20 9V11C20 12.1046 19.1046 13 18 13H17.2423L17.7773 13.535C18.5584 14.3161 18.5584 15.5824 17.7773 16.3634L16.3631 17.7776C15.5821 18.5587 14.3158 18.5587 13.5347 17.7776L13 17.2429V18Z' fill='currentColor' />
  </svg>
)

export default Settings
