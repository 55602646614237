const colors = {
  white: '#FFFFFF',
  gray100: '#F2F2F2',
  gray200: '#CCCCCC',
  gray400: '#999999',
  gray600: '#595959',
  gray700: '#3D3D3D',
  gray800: '#191919',
  black: '#000000',

  blue100: '#E9F4FA',
  blue200: '#51A5DA',
  blue400: '#0056BC',
  blue600: '#1E72A7',
  blue800: '#0F3954',
  blueLight: '#3CB4FE',

  yellow100: '#FFF8E5',
  yellow200: '#FFC533',
  yellow400: '#FFB600',
  yellow500: '#B39219',
  yellow600: '#CC9200',
  yellow800: '#664900',

  red100: '#FDE6E5',
  red200: '#F13433',
  red300: '#E11900',
  red400: '#EE0100',
  red600: '#BE0100',
  red700: '#DD1C0E',
  red800: '#5F0000',

  green100: '#EBFAEA',
  green200: '#5ED654',
  green400: '#36CC29',
  green600: '#2BA321',
  green700: '#06892B',
  green800: '#165210',

  purple100: '#F9E8FA',
  purple200: '#CD45D4',
  purple400: '#C116C9',
  purple600: '#9A12A1',
  purple800: '#4D0950',

  orange: '#FF7518'
}

export default colors
