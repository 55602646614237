import React from 'react'
import NextLink from 'next/link'
import { StyledLink } from './styled'

export interface LinkProps {
  children: React.ReactNode
  href?: string
  target?: string | boolean
  rel?: string
  as?: string
}

const Link = ({ children, href, as, target, ...props }: LinkProps) => {
  const isAbsoluteUrl = ['http', 'https'].some((protocol) => href?.includes(protocol))

  if (!href) {
    return <StyledLink {...props}>{children}</StyledLink>
  }

  if (isAbsoluteUrl) {
    return (
      <StyledLink
        target={typeof target !== 'string' ? undefined : target}
        {...props}
        href={href}
      >
        {children}
      </StyledLink>
    )
  }

  return (
    <NextLink href={href} as={as}>
      <StyledLink
        target={typeof target !== 'string' ? undefined : target}
        {...props}
      >
        {children}
      </StyledLink>
    </NextLink>
  )
}

export default Link
