
const setItemInLocalStorage = (name: string, data: unknown) => {
  try {
    window.localStorage.setItem(name, JSON.stringify(data))
  } catch (error) {
    console.error(error)
  }
}

export default setItemInLocalStorage
