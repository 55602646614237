import styled from 'styled-components'

import Container from 'elements/Container/Container'
import Image from 'elements/Image/Image'
import Paragraph from 'styleguide/typography/Paragraph'

export const Wrapper = styled(Container)`
  max-width: 1140px;
  ${({ theme }) => `
    padding: ${theme.spacing(5, 0)};
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(5, 2)};
    }
  `}
`

export const StyledParagraph = styled(Paragraph)`
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(2)}px;
  `}
`

export const StyledImage = styled(Image)`
  display: inline-block;
  filter: grayscale(100%);
  transition: filter 250ms ease 0s;
  width: auto;
  ${({ theme }) => `
    height: ${theme.spacing(8)}px;
    margin-right: ${theme.spacing(2)}px;
  `}
  &:hover {
    filter: grayscale(0);
  }
`
