import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Logout = (props: Props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M13.288 16.7022C12.9002 16.3089 12.9046 15.6758 13.2978 15.288L17.4663 11.1772C18.1904 10.4058 18.1604 9.28404 17.4812 8.60215L13.3191 4.73236C12.9146 4.3563 12.8916 3.72355 13.2676 3.31908C13.6437 2.91461 14.2765 2.89158 14.6809 3.26764L18.8668 7.15953L18.8778 7.17035C20.3787 8.65451 20.3603 11.0372 18.9053 12.5663L18.8944 12.5778L14.7022 16.712C14.3089 17.0998 13.6758 17.0954 13.288 16.7022Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M19 11L6 11C5.44772 11 5 10.5523 5 10C5 9.44772 5.44772 9 6 9L19 9C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H5C5.55228 18 6 18.4477 6 19C6 19.5523 5.55228 20 5 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H3Z' fill='currentColor' />
  </svg>
)

export default Logout
