import { NextPageContext, GetServerSidePropsContext } from 'next'
import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'

import { RequestOptions } from './RequestOptions'
import { getCookie, prolongeCookie } from 'helpers/cookies'
import dayjs from 'helpers/dayjs'
import { COOKIES } from 'constants/index'
import runtimeConfig from 'common/config'

interface Context extends NextPageContext {
  req: IncomingMessage
}

const parseOptions = (ctx: Context | null | undefined | GetServerSidePropsContext<ParsedUrlQuery>,
  requestOptions: RequestOptions): RequestInit => {
  const bodyOptions: Partial<RequestInit> = {
    headers: {}
  }

  const affiliateDate = getCookie(ctx, COOKIES.AFFILIATE_DATE)

  if (affiliateDate) {
    bodyOptions.headers = {
      ...bodyOptions.headers,
      affiliateDate
    }
  }

  const affiliateCode = getCookie(ctx, COOKIES.AFFILIATE_CODE)

  if (affiliateCode) {
    bodyOptions.headers = {
      ...bodyOptions.headers,
      affiliateCode
    }
  }

  if (ctx) {
    bodyOptions.headers = {
      cookie: String(ctx.req.headers.cookie)
    }
  }

  if (requestOptions.body) {
    bodyOptions.body = JSON.stringify(requestOptions.body)
    bodyOptions.headers = {
      ...bodyOptions.headers,
      'Content-Type': 'application/json'
    }
  }
  const authTime = Number(runtimeConfig.publicRuntimeConfig.AUTHORIZATION_TIME) || 3600
  const authExpires = dayjs().add(authTime, 'second').toDate()

  const consultantLogin = getCookie(ctx, COOKIES.CONSULTANT_LOGIN)
  if (consultantLogin) {
    prolongeCookie(ctx, COOKIES.CONSULTANT_LOGIN, consultantLogin, authExpires)
  }

  return {
    ...requestOptions,
    body: bodyOptions.body,
    headers: {
      ...bodyOptions.headers
    }
  }
}

export default parseOptions
