import { RemoveUserActions } from './actions'
import { initialState } from './initialState'

export const reducer = (state: typeof initialState, action: RemoveUserActions) => {
  switch (action.type) {
    case 'toggleRemoveUserModal':
      return { ...state, isRemoveModalOpened: !state.isRemoveModalOpened }
    case 'toggleSubmitting':
      return { ...state, isSubmitting: action.isSubmitting, isRemoveModalOpened: !!action.isRemoveModalOpened }
    case 'handleRemoveError':
      return { ...state, isSubmitting: false, isRemoveError: true }
    case 'handleRemoveAfterError':
      return { ...initialState, isRemoveModalOpened: true }
  }
}
