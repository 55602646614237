const reBrandingColors = {
  white: '#FFFFFF',
  gray100: '#F2F2F2',
  gray200: '#CCCCCC',
  gray400: '#999999',
  gray600: '#595959',
  gray800: '#191919',
  black: '#000000',

  // PRIMARY COLORS
  blue50: '#F2F7FC',
  blue100: '#E5EEF8',
  blue200: '#CCDDF2',
  blue300: '#B2CCEB',
  blue400: '#99BBE5',
  blue600: '#669AD7',
  blue800: '#0056BC',
  blue900: '#003C84',
  blue1000: '#00224C',

  yellow50: '#FFFAEA',
  yellow100: '#FFF5D5',
  yellow200: '#FFF0C0',
  yellow300: '#FFEBAB',
  yellow400: '#FEE081',
  yellow600: '#FECC2D',
  yellow800: '#B28F1F',
  yellow900: '#665212',
  yellow1000: '#332909',

  // SECONDARY COLORS
  red50: '#FDF3F2',
  red100: '#FCE8E5',
  red200: '#F9D1CC',
  red300: '#F6BAB2',
  red400: '#F3A399',
  red600: '#ED7566',
  red800: '#E11900',
  red900: '#9E1100',
  red1000: '#5A0A00',

  green50: '#F2FAF6',
  green100: '#E6F4ED',
  green200: '#CDEADC',
  green300: '#B4DFCA',
  green400: '#9BD4B9',
  green600: '#69BF95',
  green800: '#05944F',
  green900: '#036837',
  green1000: '#023B20',

  purple50: '#FAF7FD',
  purple100: '#F5EEFA',
  purple200: '#EBDDF5',
  purple300: '#EBDDF5',
  purple400: '#D6BBEB',
  purple600: '#C29AE1',
  purple800: '#9956CD',
  purple900: '#6B3C90',
  purple1000: '#3D2252',

  orange50: '#FEFBF5',
  orange100: '#FEF6EB',
  orange200: '#FDEDD8',
  orange300: '#FBE4C4',
  orange400: '#FADBB1',
  orange600: '#F8C989',
  orange800: '#F3A53B',
  orange900: '#AA7429',
  orange1000: '#614218'

}

export default reBrandingColors
