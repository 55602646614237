import styled, { css } from 'styled-components'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

const iconStyles = css`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  height: 29px;
  width: 29px;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`

export const InfoIcon = styled(InfoOutlinedIcon)`
  ${({ theme }) => `
    color: ${theme.colors.blueLight};
  `}
  ${iconStyles}
`

export const SuccessIcon = styled(CheckCircleOutlinedIcon)`
  ${({ theme }) => `
    color: ${theme.colors.green400};
  `}
  ${iconStyles}
`

export const ErrorIcon = styled(CancelOutlinedIcon)`
  ${({ theme }) => `
    color: ${theme.colors.red400};
  `}
  ${iconStyles}
`

export const WarningIcon = styled(ErrorOutlineOutlinedIcon)`
  ${({ theme }) => `
    color: ${theme.colors.yellow400};
  `}
  ${iconStyles}
`
