import React, { RefObject } from 'react'

interface Image {
  alt?: string
  className?: string
  src: string
  imgRef?: RefObject<HTMLImageElement> | null
  onClick?: () => void
}

const Image = (props: Image) => <img ref={props.imgRef} {...props} />

export default Image
