import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Contact = (props: Props) => (
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V15C20 16.6569 18.6569 18 17 18H3C1.34315 18 0 16.6569 0 15V3ZM3 2C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H17C17.5523 16 18 15.5523 18 15V3C18 2.44772 17.5523 2 17 2H3Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M0.218142 4.51035C0.488783 4.02892 1.09845 3.85804 1.57988 4.12868L9.98407 8.8532L18.3883 4.12868C18.8697 3.85804 19.4794 4.02892 19.75 4.51035C20.0206 4.99177 19.8498 5.60145 19.3683 5.87209L10.4741 10.8721C10.1698 11.0431 9.79833 11.0431 9.49404 10.8721L0.599806 5.87209C0.118379 5.60145 -0.0524978 4.99177 0.218142 4.51035Z' fill='currentColor' />
  </svg>

)

export default Contact
