import React from 'react'

import {
  InputBaseProps,
  FormHelperText,
  InputAdornment
} from '@material-ui/core'

import FormControl from 'elements/FormControl'
import FormLabel from 'elements/FormLabel'
import { CustomInput } from './styled'

export interface InputProps extends InputBaseProps {
  elementRef?: React.Ref<HTMLInputElement>
  errorText?: string
  handleChange?(e: React.ChangeEvent<HTMLInputElement>): void
  helpText?: string
  label?: string
  required?: boolean
  max?: string
}

const FormInput = ({
  elementRef,
  startAdornment,
  errorText,
  handleChange,
  helpText,
  label,
  name,
  required,
  max,
  ...props
}: InputProps) => {
  const helperText = errorText || helpText
  return (
    <FormControl error={Boolean(errorText)} required={required}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <CustomInput
        {...props}
        inputProps={{ max }}
        ref={elementRef}
        startAdornment={
          startAdornment && (
            <InputAdornment disableTypography position='start'>
              {startAdornment}
            </InputAdornment>
          )
        }
        id={name}
        name={name}
        onChange={handleChange}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default FormInput
