import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const useActiveElementManager = (activeOnPages?: string[]) => {
  const { pathname } = useRouter()
  const [isActiveElement, setIsActiveElement] = useState(false)

  useEffect(
    () => {
      if (activeOnPages) {
        const isMatchPage = activeOnPages.some((match) => pathname.includes(match))
        setIsActiveElement(isMatchPage)
      }
    },
    [activeOnPages, pathname]
  )

  return {
    isActiveElement
  }
}

export default useActiveElementManager
