import { DependencyList, useEffect, useState } from 'react'

import useComponentSubscription from './useComponentSubscription'
import { request } from './request'

const initialDependencyList: DependencyList = []

const useFetchDataManagement = <DataType>(
  url: string,
  initialDataState: DataType,
  options?: {
    sentryErrorMessage?: string
    overrideRequestMethod?: (url: string) => Promise<DataType>,
    dependencyList?: DependencyList
  }
) => {
  const [data, setData] = useState(initialDataState)
  const [requestStatus, setRequestStatus] = useState<'loading' | 'error' | 'ok'>('loading')
  const isSubscribed = useComponentSubscription()

  const dependencyList = options?.dependencyList ?? initialDependencyList
  const requestMethod = options?.overrideRequestMethod ?? request

  useEffect(() => {
    requestMethod<DataType>(url)
      .then(response => {
        if (isSubscribed.current) {
          setData(response)
          setRequestStatus('ok')
        }
      })
      .catch(() => {
        if (options?.sentryErrorMessage) {
          console.error(options?.sentryErrorMessage)
        }
        if (isSubscribed.current) {
          setRequestStatus('error')
        }
      })
  }, dependencyList)

  return [
    data,
    requestStatus
  ] as const
}

export default useFetchDataManagement
