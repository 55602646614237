import React from 'react'

import MyAccountNavigationItem from './MyAccountNavigationItem'

import menuItems from './menuItems'

const MyAccountNavigation = () => (
  <>
    {menuItems.map(({ icon, title, link, activeOnPages, isDisabledOffline, isDisabled }) => (
      <MyAccountNavigationItem
        key={link}
        icon={icon}
        title={title}
        link={link}
        activeOnPages={activeOnPages}
        isDisabledOffline={isDisabledOffline}
        isDisabled={isDisabled}
      />
    ))}
  </>
)

export default MyAccountNavigation
