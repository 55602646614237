import { HeaderVariant } from './HeaderVariant'

export const parseHeaderVariantToComponent = (headerVariant: HeaderVariant) => {
  switch (headerVariant) {
    case 'footer':
    case 'eyebrow':
    case 'filter':
      return 'h6'
    default:
      return headerVariant
  }
}
