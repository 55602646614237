import styled from 'styled-components'

import Col from 'elements/Col'
import Container from 'elements/Container'

export const Wrapper = styled.div`
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 1px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #000000;
  }
`

export const StyledContainer = styled(Container)`
  max-width: 1140px;
  ${({ theme }) => `
    padding: ${theme.spacing(2.5, 0)};
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(2.5, 2)};
    }
  `}
`

export const StyledCol = styled(Col)`
  ${({ theme }) => `
    padding: ${theme.spacing(1.5)}px 0;
  `}
`
