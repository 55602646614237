import React, { forwardRef } from 'react'

import { TypographyProps } from '@material-ui/core'

import { StyledTypography } from './styles'

interface Props extends TypographyProps {
  size: 'xl' | 'l' | 'm' | 's'
  isItalic?: boolean
  isBold?: boolean
  renderAsListItem?: boolean
  noPaddingBottom?: boolean
}

const Paragraph = forwardRef<HTMLParagraphElement, Props>(
  (
    {
      size,
      isItalic = false,
      renderAsListItem = false,
      isBold = false,
      noPaddingBottom = false,
      ...props
    }: Props,
    ref
  ) => (
    <StyledTypography
      ref={ref}
      size={size}
      $renderAsListItem={renderAsListItem}
      $isItalic={isItalic}
      $isBold={isBold}
      $noPaddingBottom={noPaddingBottom}
      {...props}
    />
  )
)

export default Paragraph
