import styled from 'styled-components'

import Container from 'elements/Container'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: auto;
`

export const Content = styled(Container)`
  flex-grow: 1;
  max-width: 1140px;
  ${({ theme }) => `
    padding: ${theme.spacing(4, 0, 0)};
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(4, 2, 0)};
    }
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `};
`
