import { LOCAL_STORAGE_ORDER_CACHE } from '../constants'
import CacheName from '../enums/CacheName'

const cleanUpPreCacheData = () => {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_ORDER_CACHE)
    window.caches.delete(CacheName.PreCachePages).then(()=>{})
  } catch (error) {
    console.log(error)
  }
}

export default cleanUpPreCacheData
