/* eslint-disable camelcase */
import { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

import { userContext } from 'context/UserProvider/initialState'
import { viewContext } from 'context/ViewProvider/initialState'

import { ssoRequest } from 'helpers/request'
import { getCookie } from 'helpers/cookies'
import saveUserDataInCookie from '../saveUserDataInCookie'

import { COOKIES } from 'constants/index'

import { User, UserResponse } from '../types'
import Method from 'helpers/request/Method'

const useUserManager = () => {
  const router = useRouter()
  const { handleStateChange } = useContext(userContext)
  const { isOnline } = useContext(viewContext)

  useEffect(() => {
    if (!isOnline) {
      const serializedUserData = getCookie(undefined, COOKIES.USER_DATA)
      const isOfflineSyncEnabled = getCookie(undefined, 'offlinecache')

      if (serializedUserData) {
        const userData: User = JSON.parse(serializedUserData)

        const dataToChange = {
          ...userData,
          isLoggedIn: true,
          isOfflineUserData: true
        }

        if (isOfflineSyncEnabled) {
          dataToChange.isOfflineSyncEnabled = Boolean(Number(isOfflineSyncEnabled))
        }

        handleStateChange(dataToChange)
      }
    }
  }, [isOnline])

  useEffect(() => {
    const userDataCookies = getCookie(undefined, COOKIES.USER_DATA)
    const isLoginView = router.pathname.includes('/login') || router.pathname.includes('/signup')
    if (!isLoginView) {
      ssoRequest<UserResponse>('/user-data', { method: Method.GET, cache: 'no-store' })
        .then((response) => {
          const {
            firstName,
            lastName,
            avatarUrl,
            email,
            address,
            country,
            dateOfBirth,
            phone,
            postal_code,
            isConfirmed
          } = response || {}
          const consultantLogin = getCookie(undefined, COOKIES.CONSULTANT_LOGIN)
          const userData = {
            firstName: firstName,
            lastName: lastName,
            avatarSrc: avatarUrl,
            dateOfBirth: dateOfBirth,
            country: country,
            address: address,
            postalCode: postal_code,
            email: email,
            phone: phone,
            isLoggedIn: !!response,
            nameFirstLetter: firstName ? firstName[0] : '',
            consultantLogin: consultantLogin,
            isConfirmed: isConfirmed
          }

          handleStateChange(userData)
          saveUserDataInCookie(userData)
        })
        .then(() => {
          if (window && window.dataLayer && !userDataCookies) {
            window.dataLayer.push({
              event: 'account',
              action: 'log_in'
            })
          }
        })
        .catch(() => {
          console.error('Warning: Nie udało się pobrać danych użytkownika.')
        })
    }
  }, [])
}

export default useUserManager
