import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import { initialState, UserState } from './initialState'

import { ssrRequest } from 'helpers/request'
import Method from 'helpers/request/Method'
import { ROUTES } from 'constants/index'

const useUserStateManager = (initialUserContext: UserState = initialState) => {
  const [state, setState] = useState(initialUserContext)
  const router = useRouter()

  const handleStateChange = useCallback(
    (data: Partial<UserState>) => {
      setState({
        ...state,
        ...data
      })
    },
    [state, setState]
  )

  const clearState = useCallback(() => {
    setState({ ...initialState })
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: 'account',
        action: 'log_out'
      })
    }
  }, [setState])

  const logout = useCallback(
    () =>
      ssrRequest<void>(
        undefined,
        '/api/logout',
        {
          method: Method.GET
        },
        window.location.origin
      ).then((response) => {
        clearState()
        return router.push(ROUTES.SIGN_UP).then(() => response)
      }),
    [router, clearState]
  )

  return {
    ...state,
    logout,
    handleStateChange,
    clearState
  }
}

export default useUserStateManager
