class ErrorPageLinksCollection {
  static createFromEnv (envs) {
    if (typeof envs === 'string') {
      const envKeys = envs.split(',')
      return envKeys.map(key => {
        const element = String(process.env[key]).replace('_', ' ').split(',')

        if (element.length !== 2) {
          console.error(`[ERROR_PAGE_LINKS] Niepoprawna ilość elementów dla ENVa: ${key}`)
          return {}
        }

        return {
          name: element[0],
          url: element[1]
        }
      }).filter(item => item.name !== undefined && item.url !== undefined)
    }
    return []
  }
}

module.exports = ErrorPageLinksCollection
