import React from 'react'

import MUIContainer from '@material-ui/core/Container'
import { ContainerProps } from '@material-ui/core'

interface Container extends ContainerProps {}

const Container = (props: Container) => <MUIContainer {...props} />

export default Container
