import styled from 'styled-components'

const StyledWrapper = styled.div`
  background: #f2f2f2;
  padding: 24px 0;
  text-align: center;
`

export {
  StyledWrapper
}
