import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Voucher = (props: Props) => (
  <svg width='22' height='18' viewBox='0 0 22 18' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M0.5 6.375C0.5 5.75368 1.00368 5.25 1.625 5.25L20.375 5.25C20.9963 5.25 21.5 5.75368 21.5 6.375C21.5 6.99632 20.9963 7.5 20.375 7.5L1.625 7.5C1.00368 7.5 0.5 6.99632 0.5 6.375Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M4.25 10.125C4.25 9.50368 4.75368 9 5.375 9L9.125 9C9.74632 9 10.25 9.50368 10.25 10.125C10.25 10.7463 9.74632 11.25 9.125 11.25L5.375 11.25C4.75368 11.25 4.25 10.7463 4.25 10.125Z' fill='currentColor' />
    <path fillRule='evenodd' clipRule='evenodd' d='M0.5 3.375C0.5 1.92525 1.67525 0.75 3.125 0.75H18.875C20.3247 0.75 21.5 1.92525 21.5 3.375V14.625C21.5 16.0747 20.3247 17.25 18.875 17.25H3.125C1.67525 17.25 0.5 16.0747 0.5 14.625V3.375ZM3.125 3C2.91789 3 2.75 3.16789 2.75 3.375V14.625C2.75 14.8321 2.91789 15 3.125 15H18.875C19.0821 15 19.25 14.8321 19.25 14.625V3.375C19.25 3.16789 19.0821 3 18.875 3H3.125Z' fill='currentColor' />
    <path d='M17.75 11.625C17.75 12.6605 16.9105 13.5 15.875 13.5C14.8395 13.5 14 12.6605 14 11.625C14 10.5895 14.8395 9.75 15.875 9.75C16.9105 9.75 17.75 10.5895 17.75 11.625Z' fill='currentColor' />
  </svg>
)

export default Voucher
